/** All the side effect css need to be imported first (disabled ordered imports) */
/* tslint:disable:no-import-side-effect no-submodule-imports ordered-imports*/
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.min.css";

// https://mui.com/material-ui/react-typography/#general
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter as Router } from "react-router-dom";

import * as React from "react";
import * as ReactDOM from "react-dom";


import { texprUiConfig, UiConfig } from "./adl-gen/whistle/xsync/uiconfig";
import { RESOLVER } from "./adl-gen/resolver";
import { createJsonBinding } from "./adl-gen/runtime/json";
import { App } from "./app/app";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createApiServices } from "./service/makeApiServices";

if (module.hot) {
  module.hot.accept();
}

export declare const window: Window & {
  /** UI config JSON injected via index.html */
  UI_CONFIG: {};
};

// Fetch and parse the typed UiConfig record from the window
// This has been populated via a script tag in index.html
function loadUiConfig(): UiConfig {
  if (!window.UI_CONFIG) {
    throw new Error("UI_CONFIG not populated");
  }
  try {
    return createJsonBinding(RESOLVER, texprUiConfig()).fromJson(
      window.UI_CONFIG
    );
  } catch (e) {
    throw new Error("Could not parse UI_CONFIG: " + e);
  }
}

const UI_CONFIG: UiConfig = loadUiConfig();

// tslint:disable:no-console
console.log("Loading App");
console.log(`Environment: ${UI_CONFIG.environment}`);
console.log(`Release name: ${UI_CONFIG.releaseName}`);
const theme = createTheme({
  // https://material-ui.com/customization/color/#playground
  palette: {
    primary: {
      main: "#0d47a1"
    },
    secondary: {
      main: "#ff8f00"
    }
  }
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App createApiServices={createApiServices} uiconfig={UI_CONFIG} />
    </ThemeProvider>
  </Router>,
  document.getElementById("root")
);
