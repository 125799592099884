import * as React from 'react';
import styled from 'styled-components';


export interface InternalLinkProps {
    text: string,
    href: string,
    onClick(): void;
}

/**
 * A link to an internal part of the app
 * 
 * The onclick handler is called for a normal mouse click, whereas a middle
 * click creates a new browser tab (and hence reloads a new copy of the app)
 */
export function InternalLink(props: InternalLinkProps) {

    function onClick(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        props.onClick();
        ev.preventDefault();
        return false;
      }
    
    return <TablelinkSty onClick={onClick} href={props.href}>{props.text}</TablelinkSty>
}

const TablelinkSty = styled.a`
  color: blue
`
