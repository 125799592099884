import * as React from "react";

import { useLoggedInUserContext } from "../../../hooks/loggedInContext";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OrgListEntry, OrgSyncStats } from "../../../adl-gen/whistle/xsync/api";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import { XeroDisconnectButton } from "../xero/xeroButtons";
import { sleep } from "../../../tslibs/hx/src/util/timers";

interface Ctx {
  reloadOrgs: ()=>Promise<void>
}

interface Column<T> {
  header: string;
  renderCell: (t: T, ctx: Ctx) => JSX.Element;
}

const cols : Column<OrgListEntry>[] = [
  {
    header: "Id",
    renderCell: (val)=><div>{val.xeroOrg.id}</div>
  },
  {
    header: "Name",
    renderCell: (val)=><div>{val.xeroOrg.value.name}</div>
  },
  {
    header: "Description",
    renderCell: (val)=><div>{val.xeroOrg.value.description}</div>
  },
  {
    header: "Access",
    renderCell: (val)=><div>{val.accessType}</div>
  },
  {
    header: "Org Status",
    renderCell: (val: OrgListEntry)=> {
      if(!val.orgSyncStats.enabled) {
        return <div>Disconnected</div>
      }
      return <div>{val.status}</div>;
    }
  },
  /*{
    TODO needs to be admin only
    header: "Actions",
    renderCell: (val)=><Link to={`/admin/value/xero_organisations/${val.xeroOrg.id}`}>edit</Link>
  }*/

  {
    header: "Journals",
    renderCell: (val)=><div>{val.orgSyncStats.countJournals}</div>
  },
  {
    header: "Accounts",
    renderCell: (val)=><div>{val.orgSyncStats.countAccounts}</div>
  },
  {
    header: "Latest Change",
    renderCell: (val)=>{
        if(val.orgSyncStats.latestUpdate) {
          return <div>{moment.utc(val.orgSyncStats.latestUpdate).format('YYYY-MM-DD')}</div>
        } else {
          return <div>n/a</div>
        }
      }
  },

  {
    header: "Sync Status",
    renderCell: (val)=><SyncStatus orgSyncStats={val.orgSyncStats}/>
  },
  {
    header: "Disconnect",
    renderCell: (val, ctx)=> val.orgSyncStats.enabled && <XeroDisconnectButton org={val} reloadOrgs={ctx.reloadOrgs}/> || <></>
  }
]

function SyncStatus(props: {orgSyncStats: OrgSyncStats}) {
  const {orgSyncStats: v} = props;

  const now = moment();
  const recent = moment(now).subtract({hour: 2});
  const old = moment(now).subtract({days: 1});

  if(v.latestSyncAttempt === null) {
    return <Chip label={"Not Started"} color="warning"></Chip>;
  }
  if(v.latestSyncProgress !== null && moment(v.latestSyncProgress).isAfter(recent) ) {
    return <Chip label={"In Progress"} color="default"></Chip>;
  }
  if(v.latestSyncSuccess === null) {
    return <Chip label={"Error"} color="error"></Chip>;
  }
  if(v.latestUpdate === null) {
    return <Chip label={"No Journals"} color="warning"></Chip>;
  }

  const syncAt = moment(v.latestSyncSuccess);

  if(syncAt.isBefore(old)) {
    return <Chip label={"Out of date"} color="warning"></Chip>;
  }

  return <Chip label={"OK"} color="success"></Chip>;
}

function OrgsTable(props: {rows: OrgListEntry[], reloadOrgs: ()=>Promise<void>}) {
  const {rows} = props;

  const ctx : Ctx = {reloadOrgs: props.reloadOrgs};

  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="organisations table">
      <TableHead>
        <TableRow>
          {cols.map(c=><TableCell key={c.header}>
            {c.header}
          </TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(r=><TableRow key={r.xeroOrg.id}>
          {cols.map(c=><TableCell key={c.header}>
            {c.renderCell(r, ctx)}
          </TableCell>)}
        </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
}

export const OrganisationsComponent = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<OrgListEntry[]>([]);
  const { app: service } = useLoggedInUserContext().apis;

  const loadOrgs = async () => {
    setLoading(true);
    const orgs = await service.getOrganisations();
    setRows(orgs);
    setLoading(false);
  };

  React.useEffect(() => {
    void loadOrgs();
  }, []);

  return (
    <>
      <h4>Organisations</h4>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <OrgsTable
          reloadOrgs={loadOrgs}
          rows={rows}
        />
      )}
    </>
  );
};
