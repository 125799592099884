import React, { useEffect } from "react";
import { PublicLayout } from "../layout/PublicLayout";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { UiConfig } from "../../adl-gen/whistle/xsync/uiconfig";
import { LoginState } from "../../app/identity-state";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/AppRoutes";

export interface LoginReturnProps {

  loginState: LoginState;

  /** Error message to show upon login failure */
  errorMessage?: string;

  /** Callback to loginReturn */
  onLoginReturn(authToken: String): void;

  /*to get the cookie name out*/
  uiConfig: UiConfig;
}

export function LoginReturnPage(props: LoginReturnProps) {
  const history = useHistory();
  const cookieName = props.uiConfig.authCookieName;

  const cookieValue = document.cookie.split('; ')
    .filter(row => row.startsWith(cookieName))
    .map(c=>c.split('=')[1])[0];

  if (cookieValue) {
    props.onLoginReturn(cookieValue);
    /* Overwriting the cookie as expired */
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  useEffect(()=>{
    if(props.loginState.user) {
      history.push(AppRoutes.Index);
    }
  },[props.loginState.user]);


  return (
    <PublicLayout>
      <Container maxWidth="xs">
        <Alert severity="info">You have been logged in</Alert>
      </Container>
    </PublicLayout>
  );
}