import { useEffect, useReducer, useState } from "react";

import { Paginated } from "../../adl-gen/common";



/**
 * Stateful interface for a page of loaded row of Type T, with a view
 * (ie filtering, sorting etc) of type V
 */
export interface LoadedTableState<T, V> {
  loadedRows: Paginated<T> | null;
  canPageBack: boolean;
  canPageForward: boolean;
  rowsLoading: boolean;
  view: V;
  pageBack(): void;
  pageForward(): void;
  reload(): void;
  setTableView(view: V): void;
};


export function createLoadedTableState<T,V>(
    tableName: string, 
    initialView: V,
    pageSize: number,
    doLoadRows: (offset: number, view :V) => Promise<Paginated<T>>

  ) : LoadedTableState<T, V> {

  const [offset, setOffset] = useState<number>(0);
  const [view, setView] = useState<V>(initialView);
  const [reloadVersion, reload] = useReducer(x => x + 1, 0);
  const [loadedRows, setLoadedRows] = useState<Paginated<T>|null>(null);
  const [rowsLoading, setRowsLoading] = useState<boolean>(false);

  useEffect(() => {
    void loadRows();
  }, [tableName, offset, view, reloadVersion]);

  async function loadRows(): Promise<void> {
    setRowsLoading(true);
    const rows = await doLoadRows(offset, view);
    setLoadedRows(rows);
    setRowsLoading(false);
  };

  const canPageForward =
      !rowsLoading &&
      loadedRows !== null &&
      loadedRows.current_offset + loadedRows.items.length < loadedRows.total_size;

  const canPageBack = !rowsLoading && loadedRows !== null && loadedRows.current_offset > 0;

  function pageBack() {
    if (canPageBack) {
      setOffset( o => o - pageSize)
    }
  };

  function pageForward() {
    if (canPageForward) {
      setOffset( o => o + pageSize)
    }
  };

  return {
      view,
      loadedRows,
      rowsLoading,
      canPageBack,
      canPageForward,
      pageBack,
      pageForward,
      reload,
      setTableView: (tv) => {
        setView(tv);
        setOffset(0);
      },
  };
}
