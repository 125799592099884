import React from "react";
import { UserLayout } from "../layout/UserLayout";

import Container from "@mui/material/Container";

import { useLoggedInUserContext } from "../../hooks/loggedInContext";
import { AppService } from "../../adl-gen/app-service";
import { ShowPlanPrices } from "../components/subscription/plans";
import { StripePrice } from "../../adl-gen/whistle/xsync/api";
import { Alert, CircularProgress } from "@mui/material";
import { useCheckSubscription } from "../components/subscription/useCheckSubscription";

export function SubscriptionPage() {
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;
  const {loading, checkResp} = useCheckSubscription();

  if(loading || checkResp === null) {
    return (
      <UserLayout>
        <Container maxWidth="xs">
          <CircularProgress/>
        </Container>
      </UserLayout>
    );
  }

  if(checkResp.subscription === null) {
    return (
      <UserLayout>
        <Container>
          <ShowPlanPrices
            onSelectPlan={async (price: StripePrice)=>{
              const resp = await service.createCheckoutSession({
                appUserId: loggedInUser.profile.id,
                email: loggedInUser.profile.email,
                priceId: price.id
              });
  
              // redirects to stripe checkout for the nominated product price
              window.location.replace(resp.redirectUrl);
            }}
          />
        </Container>
      </UserLayout>
    );
  }

  return (
    <UserLayout>
      <Container maxWidth="xs">
        <Alert severity="info">Subscription active</Alert>
      </Container>
    </UserLayout>
  );
  
  
}
