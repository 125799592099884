import { assertNever } from "@hx/util/types";
import React, { useState } from "react";
import { Button, Dropdown, Header, Icon, Modal } from "semantic-ui-react";
import styled from 'styled-components';

import { AdlEditor } from "../../adl-editor";
import { TableColumn } from "../../adl-gen/common/adminui/api";
import { DbKey, WithDbId } from "../../adl-gen/common/db";
import { SortDirection, TableView } from "../../adl-gen/common/tabular";
import { texprWord32 } from "../../adl-gen/runtime/adl";
import { scopedNamesEqual } from "../../adl-gen/runtime/utils";
import * as AST from "../../adl-gen/sys/adlast";
import * as AT from "../../adl-table";
import { createAdlTree } from "../../adl-tree";
import { createVEditor } from "../../adl-veditor";
import { AdlTable, AdlTableProps } from "../../components/AdlTable";
import { InternalLink } from "../../components/InternalLink";
import { AdminService } from "../service";
import { AdminHrefFactory, createTableMetadata, dbKeyExtractor, Metadata, TableMetadata, TSRow, unpackTsRowId } from "../utils";

import { createTablePageState } from "./table-page-state";

export interface AdminUiTablePageProps {
  tableName: string;
  metadata: Metadata;
  service: AdminService,
  hrefFactory: AdminHrefFactory,
  onGotoValue(tableName: string, id: string): void;
  onDone(): void;
  customDbRowMenuItems: CustomDbRowMenuItem[];
}


export interface CustomDbRowMenuItem {
  dbtype: AST.ScopedName,
  label: string,
  onclick(id : DbKey<unknown>): void;
};

type ModalState =
  | { kind: "add" }
  | { kind: "edit"; value: WithDbId<TSRow> }
  | { kind: "delete"; value: WithDbId<TSRow> }
  | { kind: "download" }
  ;

export const AdminUiTablePage = (props: AdminUiTablePageProps): JSX.Element => {
  const [modalState, setModalState] = useState<ModalState | null>(null);
  const [columnPropsShown, setColumnPropsShown] = useState<string | null>(null);
  const rowmetadata: TableMetadata = createTableMetadata(props.metadata, props.tableName, props.hrefFactory, true);
  const editormetadata: TableMetadata = createTableMetadata(props.metadata, props.tableName, props.hrefFactory, false);
  const snTable: AST.ScopedName = {moduleName: rowmetadata.table.declModuleName, name: rowmetadata.table.declName};
  const tablePageState = createTablePageState(props.service, props.metadata, rowmetadata);

  const renderPage = (): JSX.Element => {
    const columns = getColumns();
    const modal
      = modalState !== null ? renderModal(modalState)
        : tablePageState.pendingDbError ? renderDbError(tablePageState.pendingDbError, clearDbError)
          : null;
    return (
      <Page>
        <Topbar1>
          <Title>{props.tableName}</Title>
          <AT.IconButton name="window close outline" onClick={props.onDone} />
        </Topbar1>
        <Topbar2>
          <p>{rowmetadata.table.description}</p>
        </Topbar2>
        {renderFilter()}
        <Topbar3>
          {renderLeftPageButtons()}
          {renderRightPageButtons()}
        </Topbar3>
        <Tableholder>
          <AdminTable columns={columns} values={tablePageState.loadedRows ? tablePageState.loadedRows.items : []} />
        </Tableholder>
        {modal}
      </Page>
    );
  };

  const renderLeftPageButtons = (): JSX.Element => {
    const menuButton = (
      <Dropdown button icon='bars' className='icon' direction='right' disabled={tablePageState.rowsLoading}>
        <Dropdown.Menu>
          <Dropdown.Item
            text='Download as JSON'
            disabled={tablePageState.rowsLoading}
            onClick={() => {
              setModalState({kind:'download'});
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
    return (
      <div>
        {menuButton}
      </div>
    );
  }

  const renderRightPageButtons = (): JSX.Element => {
    const addButton = !rowmetadata.table.allowInsert ? (
      undefined
    ) : (
        <AT.PageButton tooltip="Add a new row" icon="plus" onClick={addRow} />
      );
    const prevPageButton = (
      <AT.PageButton
        tooltip={"Previous page"}
        icon="left arrow"
        disabled={!tablePageState.canPageBack}
        onClick={tablePageState.pageBack}
      />
    );
    let pageLocation = <span>...</span>;
    const page = tablePageState.loadedRows;
    if (page !== null) {
      const fromi = page.current_offset + 1;
      const toi = fromi + page.items.length - 1;
      const total = page.total_size;
      pageLocation = (
        <Pagelocation>
          {fromi}-{toi}/{total}
        </Pagelocation>
      );
    }
    const nextPageButton = (
      <AT.PageButton
        tooltip={"Next page"}
        icon="right arrow"
        disabled={!tablePageState.canPageForward}
        onClick={tablePageState.pageForward}
      />
    );
    const refreshButton = (
      <AT.PageButton
        tooltip={"Refresh"}
        icon="refresh"
        disabled={tablePageState.rowsLoading}
        loading={tablePageState.rowsLoading}
        onClick={tablePageState.reload}
      />
    );
    return (
      <div>
        {addButton}
        {prevPageButton}
        {pageLocation}
        {nextPageButton}
        {refreshButton}
      </div>
    );
  };

  const renderModal = (state: ModalState): JSX.Element => {
    if (state.kind === "add") {
      const header = "Adding new " + editormetadata.table.label + " value";
      const content = (
        <AdlEditor
          value={null}
          veditor={editormetadata.veditor}
          onCancel={clearModal}
          onApply={(tsrow: TSRow) => {
            tablePageState.create(tsrow);
            clearModal()
          }}
          allowRaw={editormetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "edit") {
      const header = "Editing " + editormetadata.table.label + " value with id " + state.value.id;
      const content = (
        <AdlEditor
          value={state.value.value}
          veditor={editormetadata.veditor}
          onCancel={clearModal}
          onApply={(tsrow: TSRow) => {
            tablePageState.update({ id: state.value.id, value: tsrow });
            clearModal()
          }}
          allowRaw={editormetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "delete") {
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>Delete {rowmetadata.table.name}:{state.value.id}?</Header>
          <Modal.Content style={{ margin: 0 }}>
            <div>
              Please confirm you wish to delete {state.value.id} from {rowmetadata.table.name}.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={clearModal}>
              <Icon />Cancel
            </Button>
            <Button color="red" onClick={() => {
              tablePageState.deletev(state.value.id);
              clearModal()
            }}>

              <Icon name="checkmark" />Delete
            </Button>
          </Modal.Actions>
        </Modal>
      );
    } else if (state.kind === "download") {
      const veditor = createVEditor(texprWord32(), props.metadata.resolver);
      const content = (
        <AdlEditor
          value={1000}
          veditor={veditor}
          onCancel={clearModal}
          onApply={(maxRows: number) => {
            clearModal()
            void downloadJson(props.tableName, props.service, tablePageState.view, maxRows); 
          }}
        />
      );
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>{"Download from " + editormetadata.table.label + " table"}</Header>
          
          <Modal.Content style={{ margin: 0 }}>Max rows to download: {content}</Modal.Content>
        </Modal>
      );
    } else {
      return assertNever(state);
    }

  };

  const renderFilter = (): JSX.Element | undefined => {
    const view = tablePageState.view;
    if(!view) {
      return undefined;
    }
    return (
      <Filterholder>
        <AT.FilterView filter={view.filter} onClearFilter={onClearFilter} />
      </Filterholder>
    );
  }

  const getColumns = (): AdminTableColumn[] => {
    const dbColumns: AdminTableColumn[] = [];
    const view = tablePageState.view;

    // The action button column
    dbColumns.push(createActionsColumn());

    // The id column
    if (rowmetadata.table.hasIdPrimaryKey) {
      dbColumns.push(createIdColumn(view));
    }

    // All of the simple data fields - ie those that can be displayed/edited
    rowmetadata.table.columns.forEach(tcol => {
      const acol = createDbColumn(tcol, view);
      if (acol !== null) {
        dbColumns.push(acol);
      }
    });

    return dbColumns;
  };

  const createIdColumn = (view: TableView | null): AdminTableColumn => {

    const label = "ID";
    const header
      = view === null
        ? AT.cellContent(label)
        : createHeaderCell(view, label, "id");

    return {
      id: "id",
      header,
      content: (tsrow:TSRow) => {
        const id = unpackTsRowId(tsrow).id;
        return AT.cellContent(tableValueLink(id, rowmetadata.table.name, id))
      }
    };
  };

  const createActionsColumn = (): AdminTableColumn => {
    return {
      id: "actions",
      header: AT.cellContent(""),
      content: (tsrow0: TSRow) => {
        const tsrow = unpackTsRowId(tsrow0);
        const menuItems = props.customDbRowMenuItems.filter(mi => scopedNamesEqual(mi.dbtype,snTable ));
        const menuButton = (
            <Dropdown icon='bars' className='icon' direction='right' disabled={tablePageState.rowsLoading}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Edit ..."
                  disabled={tablePageState.rowsLoading}
                  onClick={() => editRow(tsrow)}
                 />
                <Dropdown.Item
                  text="Delete ..."
                  disabled={tablePageState.rowsLoading}
                  onClick={() => deleteRow(tsrow)}
                />
                {menuItems.map( mi => <Dropdown.Item
                  text={mi.label}
                  onClick={() => mi.onclick(tsrow.id)}
                 />
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        return AT.cellContent(menuButton);
      }
    };
  };

  function tableValueLink(text: string, table: string, id: string): JSX.Element {
    
    return <InternalLink
      text={text}
      onClick={() => gotoValue(table, id)}
      href={props.hrefFactory.fromAdminRoute({ route: 'value', table, id })}
    />;
  }

  const createDbColumn = (
    tcol: TableColumn,
    view: TableView | null
  ): AdminTableColumn | null => {


    type  CellRenderer = (v: unknown) => AT.CellContent;
    
    function cellRenderer(typeExpr : AST.TypeExpr): CellRenderer|null {

      const extractor = dbKeyExtractor(typeExpr, rowmetadata.resolver);
      if (extractor) {
        const table = tablePageState.getDbKeyTable(extractor.scopedName);
        return (v: unknown) => {
          const id = extractor.getKey(v);
          if (id === undefined) {
            return null;
          }
          const ilink = tableValueLink(id, table, id);
          
          const label = tablePageState.getDbKeyLabel(extractor.scopedName, id);
          if (label.length > 0) {
            return AT.cellContent(<span>{ilink} <Idlabel>({label})</Idlabel></span>);
          } else {
            return AT.cellContent(ilink);
          }
        };
      }
      
      const adlTree = createAdlTree(tcol.typeExpr, rowmetadata.resolver);
      const fieldfns = AT.getFieldFns(
        rowmetadata.resolver,
        null,
        null,
        adlTree,
        rowmetadata.customize.getCustomField
      );
      if (fieldfns) {
          // Generator for any type that can be rendered as a text string
          return (v: unknown) => AT.cellContent(fieldfns.toText(v));  
      }
      return null;
    }

    const toCellContent = cellRenderer(tcol.typeExpr);
    if (toCellContent === null) {
      return null;
    }

    const header
      = view === null
        ? AT.cellContent(tcol.label)
        : createHeaderCell(view, tcol.label, tcol.name);

    return {
      id: tcol.name,
      header,
      content: (tsrow: TSRow) => toCellContent(tsrow[tcol.name])
    };
  };

  const createHeaderCell = (view: TableView, label: string, fieldname: string): AT.CellContent => {
    return AT.cellContent(
      <AT.HeaderCell
        label={label}
        sort={AT.getViewSort(view, fieldname)}
        filter={AT.getFieldFilter(view.filter, fieldname)}
        showProps={columnPropsShown === fieldname}
        onSort={(dir) => onSort(fieldname, dir)}
        onFilter={(pattern) => onFilter(fieldname, pattern)}
        onShowProps={(show) => setColumnPropsShown(show ? fieldname : null)}
      />
    );
  }

  const editRow = (value: WithDbId<TSRow>) => {
    setModalState({ kind: "edit", value });
  };

  const deleteRow = (value: WithDbId<TSRow>) => {
    setModalState({ kind: "delete", value });
  };

  const addRow = () => {
    setModalState({ kind: "add" });
  };

  const gotoValue = (table: string, id: string) => {
    props.onGotoValue(table, id);
  };

  const onSort = (fieldname: string, direction: SortDirection) => {
    const view = tablePageState.view;
    if (view) {
      const view1 = AT.withViewSort(view, fieldname, direction);
      tablePageState.setTableView(view1);
      setColumnPropsShown(null);
    }
  };

  const onFilter = (fieldname: string, pattern: string) => {
    const view = tablePageState.view;
    if (view) {
      const view1 = { ...view };
      view1.filter = AT.withFieldFilter(view1.filter, fieldname, pattern);
      tablePageState.setTableView(view1);
    }
  };

  const onClearFilter = () => {
    const view = tablePageState.view;
    if (view) {
      const view1 = { ...view };
      view1.filter = { kind: 'literal', value: true };
      tablePageState.setTableView(view1);
    }
  };

  const clearModal = () => {
    setModalState(null);
  };

  const clearDbError = () => {
    setModalState(null);
    tablePageState.clearDbError();
  };

  return renderPage();
}

async function downloadJson(table: string, service: AdminService, view: TableView, maxRows: number) {
  // Fetch the data
  const results = await service.query({
    table,
    columns: view.columns,
    query: {
      filter: view.filter,
      sorting: view.sorting,
      offset: 0,
      count: maxRows
    },
  });
  const blob = new Blob([JSON.stringify(results.items, null, 2)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = table + '.json';
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
}


export function renderDbError(dbError: string, onClose: () => void): JSX.Element {
  return (
    <Modal open={true} onClose={onClose}>
      <Header>Database Error</Header>
      <Modal.Content style={{ margin: 0 }}>
        <div>{dbError}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Ok</Button>
      </Modal.Actions>
    </Modal>
  );
}

export type AdminTableColumn = AT.Column<unknown, string>;

function AdminTable(props: AdlTableProps<TSRow, string>): JSX.Element {
  return React.createElement(AdlTable, props);
}



const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`

const Title = styled.h1`
  color: black;
  display: inline-block;
`

const Topbar1 = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`

const Topbar2 = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 14px;
`

const Topbar3 = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
  font-size: 14px;
`

const Tableholder = styled.div`
  font-size: 10px;
`

const Filterholder = styled.div`
  font-size: 14px;
`

const Pagelocation = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`

const Idlabel = styled.span`
  font-style: italic;
`
