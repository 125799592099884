// layout for Public (non logged in) pages

import React from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/AppRoutes";
import { Layout } from "./Layout";

export interface PublicLayoutProps {
  children?: React.ReactNode;
}

export function PublicLayout(props: PublicLayoutProps) {
  const history = useHistory();

  return (
    <Layout
      menuItems={[
        {
          text: "Register",
          onClick: () => {
            history.push(AppRoutes.Register);
          }
        },
        {
          text: "Login",
          onClick: () => {
            history.push(AppRoutes.Login);
          }
        }
      ]}
    >
      {props.children}
    </Layout>
  );
}
