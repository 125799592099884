import React from "react";
import { UserLayout } from "../layout/UserLayout";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { useLoggedInUserContext } from "../../hooks/loggedInContext";
import { AppService } from "../../adl-gen/app-service";

export function MainPage() {
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;

  return (
    <UserLayout>
      <Container>
        <Stack spacing={1}>

          

        </Stack>
      </Container>
    </UserLayout>
  );
}
