import React from "react";
import { useLoggedInUserContext } from "../../hooks/loggedInContext";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export interface LayoutProps {
  title?: string;
  subTitle?: string;

  buttonItems?: AppMenuItem[];

  menuItems: AppMenuItem[];
  children?: React.ReactNode;
}

export interface AppMenuItem {
  // Display text (unique in the list of menuItems)
  text: string;

  // Action function:
  onClick: () => void;
}

function AppMenu(props: { menuItems: AppMenuItem[] }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ marginRight: 2 }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {props.menuItems.map(i => (
          <MenuItem
            key={i.text}
            onClick={() => {
              handleClose();
              i.onClick();
            }}
          >
            {i.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function Layout(props: LayoutProps) {
  const singleMenuItem =
    props.menuItems.length === 1 ? props.menuItems[0] : null;
  const multiMenuItems = props.menuItems.length > 1 ? props.menuItems : null;

  const buttonItems = props.buttonItems ?? [];

  // https://mui.com/material-ui/react-app-bar/#basic-app-bar
  return (
    <Stack>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginRight: 2, display: {xs: 'none', md: 'flex'} }}

            >
              {props.title ?? "Propte"}
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              {
                buttonItems.map(buttonItem=>
                  <Button
                    key={buttonItem.text}
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      buttonItem.onClick();
                    }}
                  >
                    {buttonItem.text}
                  </Button>
                )
              }
            </Box>

            <Typography variant="h6" component="div" sx={{ marginRight: 2 }}>
              {props.subTitle ?? ""}
            </Typography>



            {singleMenuItem && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  singleMenuItem.onClick();
                }}
              >
                {singleMenuItem.text}
              </Button>
            )}

            {multiMenuItems && <AppMenu menuItems={multiMenuItems} />}
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          padding: 2
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
}
