import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { XeroDisconnectBlue } from './xeroButtons';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '@mui/material/Button';
import { width } from '@mui/system';
import Box from '@mui/material/Box';
import { Alert, Grid, Stack, Typography } from '@mui/material';
import { OrgListEntry } from '../../../adl-gen/whistle/xsync/api';

export interface DisconnectOrgDialog {
  org: OrgListEntry;
  open: boolean;
  onDisconnect(): Promise<void>;
  onClose: () => void;
}

function DisconnectConfirmDialog(props: DisconnectOrgDialog) {
  const { onClose, open, onDisconnect } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog onClose={handleClose} open={open}>

      <Box sx={{padding: 5}}>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Alert severity="warning">
            Confirm disconnect of organsation {props.org.xeroOrg.value.name} ?
          </Alert>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button variant="contained" onClick={handleClose}>
              Keep
            </Button>
            <XeroDisconnectBlue
              onClick={async () => {
                handleClose();
                await onDisconnect();
              }}
            />
          </Stack>

          
        </Stack>
        
        
      </Box>
      
    </Dialog>
  );
}

export interface Props {
  org: OrgListEntry;
  onDisconnect(): Promise<void>;
}

export default function XeroDisconnectConfirmDialog(props: Props) {
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    console.log('bored bored bored and still bored');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Button variant="contained" color="primary" startIcon={<RemoveCircleOutlineIcon />}  onClick={handleClickOpen}>
      Disconnect
    </Button>

    <DisconnectConfirmDialog
      org={props.org}
      open={open}
      onClose={handleClose}
      onDisconnect={props.onDisconnect}
    />
  </>;
}
