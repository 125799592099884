import { useState } from "react";

import { Paginated, Unit } from "../../adl-gen/common";
import { DbResult, QueryReq } from "../../adl-gen/common/adminui/api";
import { DbKey, WithDbId } from "../../adl-gen/common/db";
import { makeTableQuery, makeTableView, TableView } from "../../adl-gen/common/tabular";
import { scopedNamesEqual } from "../../adl-gen/runtime/utils";
import * as adlast from "../../adl-gen/sys/adlast";
import { AdminService } from "../service";
import {DbKeyCache, dbKeyExtractor, mapDbValue, mapPaginated, Metadata, packTsRowId, TableMetadata, TSRow } from "../utils";

import { createLoadedTableState, LoadedTableState } from "./loaded-page-state";


/**
 * Stateful interface for a page of loaded Rows of Type WithDbId<TSRow>, which
 * can do CRUD based upon an id.
 */
export interface TablePageState extends LoadedTableState<TSRow,TableView> {
  getDbKeyTable(scopedName: adlast.ScopedName): string;
  getDbKeyLabel(scopedName: adlast.ScopedName, id: string): string;
  create(tsrow: TSRow): void;
  update(tsrow: WithDbId<TSRow>): void;
  deletev(id: DbKey<TSRow>): void;
  pendingDbError: string | null;
  clearDbError(): void;
};

const pageSize: number = 20;
const initialTableView = makeTableView({columns:[]});


export function createTablePageState(service: AdminService, metadata: Metadata, tmetadata:TableMetadata) : TablePageState {
  const [pendingDbError, setPendingDbError] = useState<string|null>(null);
  const [dbKeyCache] = useState<DbKeyCache>(() => new DbKeyCache(service));

  async function queryWithIds(req: QueryReq): Promise<Paginated<TSRow>> {
    const p = await service.query(req);
    return mapPaginated(v => packTsRowId(v.id, tmetadata.tsRowFromDbRow(v.value)), p);
  }
  
  async function queryWithoutIds(req: QueryReq): Promise<Paginated<TSRow>> {
    const p = await service.queryWithoutIds(req);
    return mapPaginated(tmetadata.tsRowFromDbRow, p);
  }
  
  async function loadRows(offset: number, tableView: TableView): Promise<Paginated<TSRow>> {
    const req: QueryReq = {
      table: tmetadata.table.name,
      columns: tableView.columns,
      query: makeTableQuery({
        offset,
        count: pageSize,
        filter: tableView.filter, 
        sorting: tableView.sorting,
      })
    };
    const result = tmetadata.table.hasIdPrimaryKey 
      ? await queryWithIds(req)
      : await queryWithoutIds(req);
    await cacheDbKeyLabels(dbKeyCache, tmetadata, result.items);
    return result;
  }

  const loadedState = createLoadedTableState(tmetadata.table.name, initialTableView, pageSize, loadRows);

  async function create(tsrow: TSRow): Promise<void> {
    const dbresult = await service.create({
      table: tmetadata.table.name,
      values: tmetadata.dbRowFromTsRow(tsrow)
    });
    updateAfterDbChange(dbresult);
  };

  async function update(tsrow: WithDbId<TSRow>): Promise<void> {
    const dbresult = await service.update({
      table: tmetadata.table.name,
      values: mapDbValue(tmetadata.dbRowFromTsRow, tsrow),
    });
    updateAfterDbChange(dbresult);
  };

  async function deletev(id: DbKey<TSRow>): Promise<void> {
    const dbresult = await service.delete({
      table: tmetadata.table.name,
      id
    });
    updateAfterDbChange(dbresult);
  };

  function updateAfterDbChange(dbresult: DbResult<Unit>) {
    if (dbresult.kind === "ok") {
      loadedState.reload();
    } else {
      setPendingDbError(dbresult.value);
    }
  }

  function clearDbError() {
    setPendingDbError(null);
  }

  return {
      ...loadedState,
      pendingDbError,
      getDbKeyTable: (scopedName) => getDbKeyTable(metadata,scopedName),
      getDbKeyLabel: (sn, id) => dbKeyCache.getLabel(sn, id), 
      create,
      update,
      deletev,
      clearDbError,
  };
}


function getDbKeyTable(metadata: Metadata, sn: adlast.ScopedName): string {
  for(const tname of metadata.tableNames) {
    const table = metadata.tableMap[tname];
    if (table) {
      const tsn =  {moduleName:table.declModuleName, name:table.declName};
      if (table && scopedNamesEqual(sn,tsn)) {
        return tname;
      }
    }
  }
  throw new Error("No db table found for " + sn.name);
};

async function cacheDbKeyLabels(dbKeyCache: DbKeyCache, tmetadata: TableMetadata, tsrows: TSRow[] ): Promise<void> {

  // First find the keys we need
  for (const tsrow of tsrows) {
    for(const tcol of tmetadata.table.columns) {
      const extractor = dbKeyExtractor(tcol.typeExpr, tmetadata.resolver);
      if (extractor) {
        const key = extractor.getKey(tsrow[tcol.name]);
        if (key !== undefined) {
          dbKeyCache.requestLabel(extractor.scopedName, key);
        }
      }
    }
  }

  // Fetch them in a batch
  await dbKeyCache.fetch();
}
