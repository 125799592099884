import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppService } from "../../adl-gen/app-service";
import { useLoggedInUserContext } from "../../hooks/loggedInContext";
import { UserLayout } from "../layout/UserLayout";
import Button from "@mui/material/Button";
import { AppRoutes } from "../../app/AppRoutes";

export function PaymentPage() {
  const { search } = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const [sessionId, setSessionId] = useState<string>("");
  const [success, setSuccess] = useState<boolean|null>();
  const [cancelled, setCancelled] = useState<boolean|null>();

  useEffect(()=>{
    const successQueryParam = searchParams.get('success') ?? "false";
    const sessionIdParam = searchParams.get("session_id") ?? "";
    
    setSessionId(sessionIdParam);
    setSuccess(successQueryParam === "true" && (sessionIdParam.length > 0));

    const cancelledQueryParam = searchParams.get('canceled') ?? "false";
    setCancelled(cancelledQueryParam === "true");
  },[]);

  if(cancelled === null || success == null) {
    return <CircularProgress/>;
  }

  if(cancelled) {
    return <UserLayout>
      <Container maxWidth="xs">
        <Alert severity="warning">Subscription setup cancelled</Alert>
      </Container>
    </UserLayout>;
  }

  if(success) {
    return <UserLayout>
      <Container maxWidth="xs">
        <PaymentSuccess sessionId={sessionId}/>
      </Container>
    </UserLayout>;
  }


  return <UserLayout>
    <Container maxWidth="xs">
      <Alert severity="error">Payment error</Alert>
    </Container>
  </UserLayout>;
}

function PaymentSuccess(props: {sessionId: string}) {
  const service: AppService = useLoggedInUserContext().apis.app;
  const {sessionId} = props;
  const history = useHistory();

  useEffect(()=>{
    service.paymentSuccess({sessionId});
  },[]);

  return <>
    <Stack spacing={1}>
      <Alert severity="success">Subscription successful</Alert>
      <Button variant="contained" color="primary" onClick={()=>{
        history.push(AppRoutes.Index)
      }}>
        OK
      </Button>
    </Stack>
  </>;
}