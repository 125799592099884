import React from "react";
import { UserLayout } from "../layout/UserLayout";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { OrganisationsComponent } from "../components/organisations-table/organisations-table";
import { useLoggedInUserContext } from "../../hooks/loggedInContext";
import { AppService } from "../../adl-gen/app-service";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCheckSubscription } from "../components/subscription/useCheckSubscription";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { XeroConnectBlue, XeroConnectButton } from "../components/xero/xeroButtons";

export function OrganisationsPage() {
  //const loggedInUser = useLoggedInUserContext();
  //const service: AppService = loggedInUser.apis.app;
  //const {loading, checkResp} = useCheckSubscription();

  return (
    <UserLayout>
      <Container>
        <Stack spacing={1}>
          <OrganisationsComponent />
          <Box>
            <XeroConnectButton/>
          </Box>

        </Stack>
      </Container>
    </UserLayout>
  );
}
