import React from "react";
import { PublicLayout } from "../layout/PublicLayout";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
export function LoggedOutPage() {
  return (
    <PublicLayout>
      <Container maxWidth="xs">
        <Alert severity="info">You have been logged out</Alert>
      </Container>
    </PublicLayout>
  );
}
