/**
 * Represents an error invoking an HTTP service method.
 */
export class HttpServiceError extends Error {
  constructor(
    /** Friendly public message */
    readonly publicMessage: string,
    /** Additional details for debugging */
    readonly additionalDetails: string,
    /** HTTP response status code */
    readonly status: number,
    /** HTTP body */
    readonly body: string
  ) {
    super(publicMessage + ": " + additionalDetails + ", body " + body);
  }
}

