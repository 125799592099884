import { FieldFns, regexStringFieldFns } from '@hx/fields';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { UpdateFn, VEditor } from '../adl-veditor';
import { AdlField } from '../components/AdlField';

import { AdminHrefFactory } from './utils';

interface DbKeyVEditorProps {
  tableName: string;
  hrefFactory: AdminHrefFactory;
}

export function dbKeyFieldFns(tableName: string): FieldFns<string> {
  return regexStringFieldFns("^\\S+$", `an existing ${tableName} id`, 0);
}

export function dbKeyVEditor(props: DbKeyVEditorProps) {
  const fieldfns = dbKeyFieldFns(props.tableName);
  return fieldVEditor(props, fieldfns);
}

type FieldState = string;
type FieldEvent = string;

export function fieldVEditor<T>(
  props: DbKeyVEditorProps,
  fieldfns: FieldFns<T>
): VEditor<T, FieldState, FieldEvent> {
  return {
    initialState: "",
    stateFromValue: v => fieldfns.toText(v),
    validate: s => listFromNull(fieldfns.validate(s)),
    valueFromState: s => fieldfns.fromText(s),
    update: (_state: FieldState, event: FieldEvent) => event,
    render: (state: FieldEvent, canEdit: boolean, onUpdate: UpdateFn<FieldEvent>) => {
      if (canEdit) {
        return (
          <FieldContainerSty>
            <AdlField fieldfns={fieldfns} text={state} disabled={false} onChange={onUpdate} />
            <LinkIconSty>
              <a href={props.hrefFactory.fromAdminRoute({ route: 'table', table: props.tableName })} target="_blank">
                <Icon name="external alternate" />
              </a>
            </LinkIconSty>
          </FieldContainerSty>
        );
      } else {
        return <IdlinkSty
          href={props.hrefFactory.fromAdminRoute({ route: 'value', table: props.tableName, id: state })}>
          {state}
        </IdlinkSty>;
      }
    }
  };
}

function listFromNull<T>(v: T | null): T[] {
  if (v === null) {
    return [];
  } else {
    return [v];
  }
}



const FieldContainerSty = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LinkIconSty = styled.span`
  margin-left: 10px;
`

const IdlinkSty = styled.a`
  color: blue
`
