import { useEffect, useState } from "react";
import { useLoggedInUserContext } from "../../../hooks/loggedInContext";
import { AppService } from "../../../adl-gen/app-service";
import { CheckSubscriptionResp } from "../../../adl-gen/whistle/xsync/api";

export function useCheckSubscription() {
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;

  const [loading, setLoading] = useState<number>(0);
  const [checkResp, setCheckResp] = useState<CheckSubscriptionResp | null>(null);

  // check for existing subscription
  const loadData = async () => {
    setLoading(l => l + 1);
    const resp = await service.checkSubscription({});
    setCheckResp(resp);
    setLoading(l => l - 1);
  };

  useEffect(() => {
    void loadData();
  }, []);

  return { loading, checkResp };
}
