import { FieldFns, jsonFieldFns } from "@hx/fields";
import deepEqual from "deep-equal";
import * as React from "react";
import { Accordion, Dropdown, DropdownProps, Header, Icon, Modal, Radio } from "semantic-ui-react";

import {
  getFormGroupsFromAnnotation,
  getFormLabelFromAnnotation,
  getGroupKeyFromAnnotation
} from "./adl-annotations";
import { AdlEditor } from "./adl-editor";
import { maybeField, nullableField, primitiveFieldFns } from "./adl-field";
import * as tabular from "./adl-gen/common/tabular";
import { ATypeExpr, DeclResolver, texprString, texprVector } from "./adl-gen/runtime/adl";
import { createJsonBinding } from "./adl-gen/runtime/json";
import {scopedNamesEqual} from "./adl-gen/runtime/utils";
import * as adlast from "./adl-gen/sys/adlast";
import * as systypes from "./adl-gen/sys/types";
import * as AT from "./adl-table";
import * as adltree from "./adl-tree";
import { AdlField } from "./components/AdlField";
import { AdlTableU } from "./components/AdlTable";

export type UpdateFn<E> = (e: E) => void;

// An abstract value editor
//
//    T: the type of value being edited
//    S: the type of state required for editing
//    E: the type of events
export interface VEditor<T, S, E> {
  // The state for an empty editor
  initialState: S;

  // Construct the state for an editor with current value T
  stateFromValue(value: T): S;

  // Check whether the current state can produce a T. Return
  // a list of errors.
  validate(state: S): string[];

  // If valid, construct a value of type T representing the current
  // value
  valueFromState(state: S): T;

  // Returns a copy of the state, updated to reflect the given event
  update(state: S, event: E): S;

  // Render the editor's current state as a UI.
  render(state: S, canEdit: boolean, onUpdate: UpdateFn<E>): JSX.Element;
}

// Identify the context for customization

export interface CustomContext {
  declResolver: DeclResolver;
  scopedDecl: adlast.ScopedDecl | null;
  field: adlast.Field | null;
  typeExpr: adlast.TypeExpr;
}

export interface Customize {
  getCustomVEditor(ctx: CustomContext): VEditor<unknown, unknown, unknown> | null;
  getCustomField(ctx: CustomContext): FieldFns<unknown> | null;
}

export const noCustomize: Customize = {
  getCustomVEditor: _ctx => null,
  getCustomField: _ctx => null
};

const nullContext = { scopedDecl: null, field: null };

export function createVEditor<T>(
  typeExpr: ATypeExpr<T>,
  declResolver: DeclResolver,
  customize?: Customize
): VEditor<T, unknown, unknown> {
  const adlTree = adltree.createAdlTree(typeExpr.value, declResolver);
  const customize1 = customize ? customize : noCustomize;
  return createVEditor0(declResolver, nullContext, adlTree, customize1) as VEditor<
    T,
    unknown,
    unknown
  >;
}

interface InternalContext {
  scopedDecl: adlast.ScopedDecl | null;
  field: adlast.Field | null;
}

function createVEditor0(
  declResolver: DeclResolver,
  ctx: InternalContext,
  adlTree: adltree.AdlTree,
  customize: Customize
): VEditor<unknown, unknown, unknown> {
  const customContext = {
    declResolver,
    scopedDecl: ctx.scopedDecl,
    field: ctx.field,
    typeExpr: adlTree.typeExpr
  };

  // Use a custom editor if available
  const customVEditor = customize.getCustomVEditor(customContext);
  if (customVEditor !== null) {
    return customVEditor;
  }
  const customField = customize.getCustomField(customContext);
  if (customField) {
    return fieldVEditor(customField);
  }

  // Otherwise construct a standard one

  const details = adlTree.details();
  switch (details.kind) {
    case "primitive":
      if (details.ptype === "Bool") {
        return boolEditor();
      } else if (details.ptype === "Void") {
        return voidEditor();
      } else if (details.ptype === "Json") {
        return fieldVEditor(jsonFieldFns());
      } else {
        const fldfns = createField(adlTree, customContext, customize);
        if (fldfns === null) {
          return unimplementedVEditor(details.kind);
        }
        return fieldVEditor(fldfns);
      }

    case "struct":
      return structVEditor(declResolver, details, customize);

    case "newtype":
      if (adlTree.typeExpr.typeRef.kind === 'reference' && scopedNamesEqual(systypes.snMap, adlTree.typeExpr.typeRef.value)) {
         return mapVEditor(declResolver, nullContext, customize, {value:adlTree.typeExpr.parameters[0]}, {value:adlTree.typeExpr.parameters[1]});
      }
      return createVEditor0(declResolver, nullContext, details.adlTree, customize);

    case "typedef":
      return createVEditor0(declResolver, nullContext, details.adlTree, customize);

    case "union":
      if (isEnum(details.fields)) {
        return enumVEditor(details.fields);
      }

      // When T can be edited in a String field, we can use a string
      // field for Maybe<T> iff the empty string is not a valid value
      // of T.  So Maybe<Int> can be editied in a string field,
      // whereas Maybe<String> cannot.
      if (isMaybe(adlTree.typeExpr)) {
        const fldfns = createFieldForTParam0(adlTree, customContext, customize, declResolver);
        if (fldfns && fldfns.validate("") !== null) {
          return fieldVEditor(maybeField(fldfns));
        }
      }

      return unionVEditor(declResolver, details, customize);

    case "nullable":
      const fieldfns = createFieldForTParam0(adlTree, customContext, customize, declResolver);
      if (fieldfns !== null) {
        return fieldVEditor(nullableField(fieldfns));
      } else {
        return nullableVEditor(declResolver, details.param, customize);
      }

    case "vector":
      const vdetails = details.param.details();
      if (vdetails.kind === "struct") {
        return structVectorVEditor(declResolver, { value: details.param.typeExpr }, customize);
      } else if (vdetails.kind === "union" && !isEnum(vdetails.fields)) {
        return unionVectorVEditor(declResolver, { value: details.param.typeExpr }, customize);
      } else {
        return vectorVEditor(declResolver, { value: details.param.typeExpr }, customize);
      }

    case "stringmap":
      // An veditor over StringMap<T> is implemented in terms of
      // An veditor over sys.types.Map<String,T>
      type MapType = systypes.MapEntry<string,unknown>[];
      interface StringMapType {[key:string]: unknown}
      const valueType = adlTree.typeExpr.parameters[0];
      const underlyingVEditor = mapEntryVectorVEditor(declResolver, ctx, customize, texprString(), {value:valueType});
      const stringMapFromMap = (m: MapType): StringMapType => {
        const result: StringMapType = {};
        for (const me of m) {
          result[me.key] = me.value;
        }
        return result;
      }
      const mapFromStringMap = (m: StringMapType): MapType => {
        return Object.keys(m).map( k => ({key:k, value:m[k]}));
      }
      return mappedVEditor(
        underlyingVEditor,
        mapFromStringMap,
        stringMapFromMap,
      );
  }
}
// Create an editor over a Vector<Pair<K,V>>. This won't be required after
// we update sys.types.Map to have that type
function mapVEditor<K,V>(declResolver: DeclResolver, ctx: InternalContext, customize: Customize, ktype: ATypeExpr<K>, vtype: ATypeExpr<V>): VEditor<systypes.Pair<K,V>[], unknown, unknown> {
  const map1 = (m: systypes.Pair<K,V>[]): systypes.MapEntry<K,V>[] => {
    return m.map( p => ({key:p.v1, value:p.v2}) );
  }
  const map2 = (m: systypes.MapEntry<K,V>[]): systypes.Pair<K,V>[] => {
    return m.map( me => ({v1:me.key, v2:me.value}) );
  }
  return mappedVEditor(
    mapEntryVectorVEditor(declResolver, ctx, customize, ktype, vtype),
    map1,
    map2,
  );
}

// Create an editor over a Vector<MapEntry<K,V>>. This won't be required after
// we update sys.types.Map to have that type
function mapEntryVectorVEditor<K,V>(declResolver: DeclResolver, ctx: InternalContext, customize: Customize, ktype: ATypeExpr<K>, vtype: ATypeExpr<V>): VEditor<systypes.MapEntry<K,V>[], unknown, unknown> {
  type MapType = systypes.MapEntry<K,V>[];
  const mapTypeExpr : ATypeExpr<MapType> = texprVector(systypes.texprMapEntry(ktype,vtype));
  const mapAdlTree = adltree.createAdlTree(mapTypeExpr.value, declResolver);
  return createVEditor0(declResolver, ctx, mapAdlTree, customize) as VEditor<MapType,unknown,unknown>;
}

function createFieldForTParam0(
  adlTree: adltree.AdlTree,
  ctx: CustomContext,
  customize: Customize,
  declResolver: DeclResolver
): FieldFns<unknown> | null {
  const adlTree1 = adltree.createAdlTree(adlTree.typeExpr.parameters[0], declResolver);
  const ctx1 = {
    declResolver,
    scopedDecl: ctx.scopedDecl,
    field: ctx.field,
    typeExpr: adlTree.typeExpr.parameters[0]
  };
  return createField(adlTree1, ctx1, customize);
}

function createField(
  adlTree: adltree.AdlTree,
  ctx: CustomContext,
  customize: Customize
): FieldFns<unknown> | null {
  let fieldfns = createField1(adlTree, ctx, customize);
  if (fieldfns === null) {
    // Try resolving through any typedefs or newtypes
    const adlTree2 = adltree.resolve(adlTree, true, true);
    fieldfns = createField1(adlTree2, ctx, customize);
  }
  return fieldfns;
}

function createField1(
  adlTree: adltree.AdlTree,
  ctx: CustomContext,
  customize: Customize
): FieldFns<unknown> | null {
  if (customize) {
    const customField = customize.getCustomField(ctx);
    if (customField) {
      return customField;
    }
  }
  const details = adlTree.details();
  if (details.kind === "primitive") {
    const fieldfns = primitiveFieldFns(details.ptype);
    if (fieldfns !== null) {
      return fieldfns;
    }
  }
  return null;
}

type FieldState = string;
type FieldEvent = string;

export function fieldVEditor<T>(fieldfns: FieldFns<T>): VEditor<T, FieldState, FieldEvent> {
  return {
    initialState: "",
    stateFromValue: v => fieldfns.toText(v),
    validate: s => listFromNull(fieldfns.validate(s)),
    valueFromState: s => fieldfns.fromText(s),
    update: (_state: FieldState, event: FieldEvent) => event,
    render: (state: FieldEvent, canEdit: boolean, onUpdate: UpdateFn<FieldEvent>) => {
      if (canEdit) {
        return <AdlField fieldfns={fieldfns} text={state} disabled={false} onChange={onUpdate} />;
      } else {
        if (fieldfns.rows > 1) {
          const lines = state.split("\n").map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          });
          return <div>{lines}</div>;
        } else {
          return <div>{state}</div>;
        }
      }
    }
  };
}

function boolEditor(): VEditor<boolean, unknown, unknown> {
  return selectionVEditor([
    { label: "False", value: false },
    { label: "True", value: true }
  ]);
}

function voidEditor(): VEditor<null, {}, {}> {
  return {
    initialState: {},
    stateFromValue: () => ({}),
    validate: () => [],
    valueFromState: () => null,
    update: state => state,
    render: () => <div />
  };
}

interface StructFieldStates {
  [key: string]: unknown;
}
interface StructState {
  fieldStates: StructFieldStates;
  activeGroups: number[];
}
interface StructFieldEvent {
  kind: "field";
  field: string;
  fieldEvent: unknown;
}
interface StructGroupEvent {
  kind: "groupclick";
  index: number;
}
type StructEvent = StructFieldEvent | StructGroupEvent;

interface StructFieldDetails {
  name: string;
  label: string;
  veditor: VEditor<unknown, unknown, unknown>;
  groupKey: string;
}
interface GroupMap {
  [key: string]: { label: string; fieldDetails: StructFieldDetails[] } | undefined;
}

function getGroupMapE(map: GroupMap, key: string): { label: string; fieldDetails: StructFieldDetails[] } {
  const v = map[key];
  if (v===undefined) {
    throw new Error("BUG: no groupmap value found for " + key);
  }
  return v;
}

function structVEditor(
  declResolver: DeclResolver,
  struct: adltree.Struct,
  customize: Customize
): VEditor<unknown, StructState, StructEvent> {
  const formGroups = getFormGroupsFromAnnotation(declResolver, struct.astDecl);
  const defaultFormGroupKey = formGroups === null ? "" : formGroups.defaultKey;
  const groupLabels = formGroups === null ? [] : formGroups.labels;

  const fieldDetails = struct.fields.map(field => {
    let groupKey = getGroupKeyFromAnnotation(declResolver, field.astField);
    if (groupKey === null) {
      groupKey = defaultFormGroupKey;
    }
    let formLabel = getFormLabelFromAnnotation(declResolver, field.astField);
    if (formLabel === null) {
      formLabel = fieldLabel(field.astField.name);
    }
    const ctx = {
      scopedDecl: { moduleName: struct.moduleName, decl: struct.astDecl },
      field: field.astField
    };

    const jsonBinding = createJsonBinding<unknown>(declResolver, { value: field.adlTree.typeExpr });

    return {
      name: field.astField.name,
      default: field.astField.default,
      jsonBinding,
      label: formLabel,
      veditor: createVEditor0(declResolver, ctx, field.adlTree, customize),
      groupKey
    };
  });

  const veditorsByName = {};
  const initialState = { fieldStates: {}, activeGroups: [] };
  const groupMap: GroupMap = { "": { label: "", fieldDetails: [] } };
  const groupKeys: string[] = [];
  for (const gl of groupLabels) {
    groupKeys.push(gl.v1);
    if (!groupMap[gl.v1]) {
      groupMap[gl.v1] = { label: gl.v2, fieldDetails: [] };
    }
  }

  // It's unclear what the initialState for an empty struct
  // editor should be... either every field empty, or
  // with default values filled in for those fields that have
  // defaults specified. the flag below set's this behaviour, though
  // we may want to change initialState to be a function that takes
  // this as a parameter.
  const USE_DEFAULTS_FOR_STRUCT_FIELDS = true;

  for (const fd of fieldDetails) {
    veditorsByName[fd.name] = fd.veditor;
    if (USE_DEFAULTS_FOR_STRUCT_FIELDS && fd.default.kind === "just") {
      initialState.fieldStates[fd.name] = fd.veditor.stateFromValue(
        fd.jsonBinding.fromJsonE(fd.default.value)
      );
    } else {
      initialState.fieldStates[fd.name] = fd.veditor.initialState;
    }
    let groupMapVal = groupMap[fd.groupKey];
    if (groupMapVal === undefined) {
      groupMapVal = {
        label: fieldLabel(fd.groupKey),
        fieldDetails: []
      };
      groupMap[fd.groupKey] = groupMapVal;
      groupKeys.push(fd.groupKey);
    }
    groupMapVal.fieldDetails.push(fd);
  }

  function stateFromValue(value: {}) {
    const state = {
      fieldStates: {},
      activeGroups: []
    };
    for (const fd of fieldDetails) {
      state.fieldStates[fd.name] = fd.veditor.stateFromValue(value[fd.name]);
    }
    return state;
  }

  function validate(state: StructState) {
    let errors: string[] = [];
    for (const fd of fieldDetails) {
      errors = errors.concat(fd.veditor.validate(state.fieldStates[fd.name]));
    }
    return errors;
  }

  function valueFromState(state: StructState) {
    const value = {};
    for (const fd of fieldDetails) {
      value[fd.name] = fd.veditor.valueFromState(state.fieldStates[fd.name]);
    }
    return value;
  }

  function update(state: StructState, event: StructEvent): StructState {
    if (event.kind === "field") {
      const newFieldStates = {};
      Object.assign(newFieldStates, state.fieldStates);
      newFieldStates[event.field] = veditorsByName[event.field].update(
        state.fieldStates[event.field],
        event.fieldEvent
      );
      return {
        fieldStates: newFieldStates,
        activeGroups: state.activeGroups
      };
    } else if (event.kind === "groupclick") {
      const newActiveGroups = state.activeGroups.filter(i => i !== event.index);
      if (newActiveGroups.length === state.activeGroups.length) {
        newActiveGroups.push(event.index);
      }
      return {
        fieldStates: state.fieldStates,
        activeGroups: newActiveGroups
      };
    } else {
      return state;
    }
  }

  function render(
    state: StructState,
    canEdit: boolean,
    onUpdate: UpdateFn<StructEvent>
  ): JSX.Element {
    function structTable(groupKey: string) {
      const fieldDetails1 = getGroupMapE(groupMap, groupKey).fieldDetails;
      const state1: StructFieldStates = {};
      for (const fd of fieldDetails1) {
        state1[fd.name] = state.fieldStates[fd.name];
      }
      return (
        <StructTable
          fieldDetails={fieldDetails1}
          onUpdate={onUpdate}
          canEdit={canEdit}
          state={state1}
        />
      );
    }
    const ungroupedTable =
      getGroupMapE(groupMap, "").fieldDetails.length > 0 ? (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>{structTable("")}</div>
      ) : null;

    const renderedGroups0: JSX.Element[] = [];
    groupKeys.forEach((gkey, i) => {
      const isActive = state.activeGroups.indexOf(i) > -1;
      function onClick() {
        onUpdate({ kind: "groupclick", index: i });
      }
      renderedGroups0.push(
        <Accordion.Title active={isActive} index={i} key={gkey + "T"} onClick={onClick}>
          <Icon name="dropdown" />
          {getGroupMapE(groupMap, gkey).label}
        </Accordion.Title>
      );
      renderedGroups0.push(
        <Accordion.Content active={isActive} index={i} key={gkey}>
          {structTable(gkey)}
        </Accordion.Content>
      );
    });

    const renderedGroupTables =
      renderedGroups0.length > 0 ? <Accordion styled>{renderedGroups0}</Accordion> : null;

    return (
      <div>
        {ungroupedTable}
        {renderedGroupTables}
      </div>
    );
  }

  return {
    initialState,
    stateFromValue,
    validate,
    valueFromState,
    update,
    render
  };
}

interface StructTableProps {
  fieldDetails: StructFieldDetails[];
  canEdit: boolean;
  state: StructFieldStates;
  onUpdate(ev: StructFieldEvent): void;
}

interface FieldUpdateMap {
  [key: string]: (v: unknown) => void;
}

class StructTable extends React.PureComponent<StructTableProps, unknown> {
  constructor(props: StructTableProps) {
    super(props);
  }

  render() {
    const onFieldUpdateMap: FieldUpdateMap = {};
    for (const fd of this.props.fieldDetails) {
      onFieldUpdateMap[fd.name] = event => {
        this.props.onUpdate({ kind: "field", field: fd.name, fieldEvent: event });
      };
    }
    const tstyle: React.CSSProperties = {
      borderCollapse: "collapse",
      borderStyle: "hidden"
    };
    const trstyle = {
      border: "1px solid lightgrey"
    };
    const tlstyle = {
      //      border: "1px solid white",
      padding: "5px"
      //      "vertical-align": "top"
    };
    const tdstyle = {
      padding: "5px"
      //      border: "1px solid white"
    };

    const rows = this.props.fieldDetails.map(fd => {
      const onFieldUpdate = onFieldUpdateMap[fd.name];
      const label = this.props.canEdit ? fd.label : <b>{fd.label}</b>;
      return (
        <tr key={fd.name} style={trstyle}>
          <td style={tlstyle}>
            <label>{label}</label>
          </td>
          <td style={tdstyle}>
            {fd.veditor.render(this.props.state[fd.name], this.props.canEdit, onFieldUpdate)}
          </td>
        </tr>
      );
    });
    return (
      <table style={tstyle}>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

interface UnionState {
  currentField: string | null;
  fieldStates: { [key: string]: unknown };
}

interface UnionSetField {
  kind: "switch";
  field: string | null;
} // Switch the discriminator
interface UnionUpdate {
  kind: "update";
  event: unknown;
} // Update the value
type UnionEvent = UnionSetField | UnionUpdate;

interface SomeUnion {
  kind: string;
  value: unknown;
}

function unionVEditor(
  declResolver: DeclResolver,
  union: adltree.Union,
  customize: Customize
): VEditor<SomeUnion, UnionState, UnionEvent> {
  const fieldDetails = union.fields.map(field => {
    let formLabel = getFormLabelFromAnnotation(declResolver, field.astField);
    if (formLabel === null) {
      formLabel = fieldLabel(field.astField.name);
    }
    const ctx = {
      scopedDecl: { moduleName: union.moduleName, decl: union.astDecl },
      field: field.astField
    };

    return {
      name: field.astField.name,
      label: formLabel,
      veditor: () => createVEditor0(declResolver, ctx, field.adlTree, customize)
    };
  });

  const veditorsByName = {};
  for (const fd of fieldDetails) {
    veditorsByName[fd.name] = fd.veditor;
  }

  const initialState = { currentField: null, fieldStates: {} };

  function stateFromValue(uvalue: SomeUnion): UnionState {
    const kind = uvalue.kind;
    if (!kind) {
      throw new Error("union must have kind field");
    }
    const value = uvalue.value === undefined ? null : uvalue.value;
    const veditor = veditorsByName[kind]();
    if (!veditor) {
      throw new Error("union with invalid kind field");
    }
    return {
      currentField: kind,
      fieldStates: { [kind]: veditor.stateFromValue(value) }
    };
  }

  function validate(state: UnionState): string[] {
    const kind = state.currentField;
    if (kind === null) {
      return ["Union selection required"];
    }
    return veditorsByName[kind]().validate(state.fieldStates[kind]);
  }

  function valueFromState(state: UnionState): SomeUnion {
    const kind = state.currentField;
    if (kind === null) {
      throw new Error("BUG: union valueFromState called on invalid state");
    }
    const value = veditorsByName[kind]().valueFromState(state.fieldStates[kind]);
    return { kind, value };
  }

  function update(state: UnionState, event: UnionEvent): UnionState {
    if (event.kind === "switch") {
      const field = event.field;
      const newFieldStates = { ...state.fieldStates };
      if (field && !newFieldStates[field]) {
        newFieldStates[field] = veditorsByName[field]().initialState;
      }
      return {
        currentField: event.field,
        fieldStates: newFieldStates
      };
    } else if (event.kind === "update") {
      const field = state.currentField;
      if (field === null) {
        throw new Error("BUG: union update received when current field not set");
      }
      const newFieldStates = { ...state.fieldStates };
      newFieldStates[field] = veditorsByName[field]().update(newFieldStates[field], event.event);
      return {
        currentField: state.currentField,
        fieldStates: newFieldStates
      };
    } else {
      return state;
    }
  }

  // Render the editor's current state as a UI.
  function render(
    state: UnionState,
    canEdit: boolean,
    onUpdate: UpdateFn<UnionEvent>
  ): JSX.Element {
    const options = [{ text: "??", value: "??" }].concat(
      fieldDetails.map(fd => ({ text: fd.label, value: fd.name }))
    );

    function onFieldChange(data: { value: string }) {
      const fld = data.value === "??" ? null : data.value;
      onUpdate({ kind: "switch", field: fld });
    }

    function onSubElementUpdate(event: unknown) {
      onUpdate({ kind: "update", event });
    }

    const value = state.currentField || "??";
    const opts: { error?: boolean } = {};
    opts.error = !state.currentField;

    let fieldDropdown: JSX.Element | null = null;
    if (canEdit) {
      fieldDropdown = (
        <Dropdown
          selection
          onChange={(_e, data) => onFieldChange({ value: String(data.value) })}
          options={options}
          value={value}
          {...opts}
        />
      );
    } else {
      fieldDropdown = <div>{value}</div>;
    }

    let subElements = null;
    const field = state.currentField;
    if (field) {
      subElements = veditorsByName[field]().render(
        state.fieldStates[field],
        canEdit,
        onSubElementUpdate
      );
    }
    return (
      <div>
        <div>{fieldDropdown}</div>
        <div>{subElements}</div>
      </div>
    );
  }

  return {
    initialState,
    stateFromValue,
    validate,
    valueFromState,
    update,
    render
  };
}

function nullableVEditor<T>(
  declResolver: DeclResolver,
  t: adltree.AdlTree,
  customize: Customize
): VEditor<T | null, unknown, unknown> {
  interface S {
    enabled: boolean;
    underlying: unknown;
  }
  type E = { kind: "enabled"; value: boolean } | { kind: "underlying"; value: unknown };

  const underlyingVEditor = createVEditor({ value: t.typeExpr }, declResolver, customize);

  return {
    initialState: {
      enabled: false,
      underlying: underlyingVEditor.initialState
    },
    validate(state: S): string[] {
      if (!state.enabled) {
        return [];
      }
      return underlyingVEditor.validate(state.underlying);
    },
    render(state: S, canEdit: boolean, onUpdate: UpdateFn<E>) {
      const nbstyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column"
      };
      const nestyle: React.CSSProperties = {
        marginTop: "3px"
      };
      return (
        <div style={nbstyle}>
          <Radio
            toggle
            readOnly={!canEdit}
            checked={state.enabled}
            onChange={(_event, props) =>
              onUpdate({ kind: "enabled", value: props.checked || false })
            }
          />

          {state.enabled ? (
            <div style={nestyle}>
              {underlyingVEditor.render(state.underlying, canEdit, value =>
                onUpdate({ kind: "underlying", value })
              )}
            </div>
          ) : null}
        </div>
      );
    },
    update(state: S, event: E): S {
      switch (event.kind) {
        case "enabled":
          return { enabled: event.value, underlying: state.underlying };
        case "underlying":
          const s1 = {
            enabled: state.enabled,
            underlying: underlyingVEditor.update(state.underlying, event.value)
          };
          return s1;
      }
    },
    stateFromValue(v: T | null): S {
      if (v === null) {
        return { enabled: false, underlying: underlyingVEditor.initialState };
      } else {
        return { enabled: true, underlying: underlyingVEditor.stateFromValue(v) };
      }
    },
    valueFromState(s: S): T | null {
      if (!s.enabled) {
        return null;
      } else {
        return underlyingVEditor.valueFromState(s.underlying) as T;
      }
    }
  };
}

function selectionVEditor<T>(choices: { label: string; value: T }[]): VEditor<T, string, string> {
  const options = [{ text: "??", value: "??" }].concat(
    choices.map(c => ({ text: c.label, value: c.label }))
  );

  function stateFromValue(value: T): string {
    for (const choice of choices) {
      if (deepEqual(choice.value,value)) {
        return choice.label;
      }
    }
    return "??";
  }

  function valueFromState(state: string): T {
    for (const choice of choices) {
      if (choice.label === state) {
        if (choice.value !== null) {
          return choice.value;
        }
      }
    }
    throw new Error("Invalid selection value");
  }

  function render(state: string, canEdit: boolean, onUpdate: UpdateFn<string>): JSX.Element {
    function onChange(_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) {
      if (data && data.value && typeof(data.value) === 'string') {
        onUpdate(data.value);
      }
    }
    const opts: { error?: boolean } = {};
    opts.error = state === "??";
    if (canEdit) {
      return <Dropdown selection onChange={onChange} options={options} value={state} {...opts} />;
    } else {
      return <div>{state}</div>;
    }
  }

  function update(_state: string, event: string) {
    return event;
  }

  return {
    initialState: "??",
    stateFromValue,
    validate: state => (state === "??" ? ["selection not made"] : []),
    valueFromState,
    update,
    render
  };
}

function enumVEditor(fields: adltree.Field[]): VEditor<string, unknown, unknown> {
  const choices = fields.map((f, i) => ({
    label: fieldLabel(f.astField.name),
    value: f.astField.name,
  }));
  return selectionVEditor(choices);
}

// A placeholder editor for unimplemented types

function unimplementedVEditor(type: string): VEditor<unknown, systypes.Maybe<unknown>, {}> {
  type S = systypes.Maybe<unknown>;

  const initialState: S = { kind: "nothing" };

  function stateFromValue(value: unknown): S {
    return { kind: "just", value };
  }

  function validate(state: S): string[] {
    if (state.kind === "nothing") {
      return ["no value"];
    }
    return [];
  }

  function valueFromState(state: S): unknown {
    if (state.kind === "just") {
      return state.value;
    }
    throw new Error("BUG: unimplemented valueFromState called on invalid state");
  }

  function update(state: S): S {
    return state;
  }

  function render(state: S): JSX.Element {
    if (state.kind === "just") {
      return <div>{"Editor for " + type + " unimplemented"}</div>;
    }
    return <div>{"Editor for " + type + " unimplemented (empty)"}</div>;
  }

  return {
    initialState,
    stateFromValue,
    validate,
    valueFromState,
    update,
    render
  };
}

interface VectorStateModalAdd {
  kind: "add";
}

interface VectorStateModalEdit<T> {
  kind: "edit";
  item: T;
  i: number;
  canEdit: boolean;
}

type VectorStateModal<T> = VectorStateModalAdd | VectorStateModalEdit<T>;

interface VectorState<T> {
  values: T[];
  modal: null | VectorStateModal<T>;
}

interface VectorEventShowModal<T> {
  kind: "show-modal";
  modal: VectorStateModal<T>;
}
interface VectorEventUpdateItem<T> {
  kind: "update-item";
  item: T;
  i: number;
}
interface VectorEventDeleteItem {
  kind: "delete-item";
  i: number;
}
interface VectorEventMoveUp {
  kind: "move-up";
  i: number;
}
interface VectorEventMoveDown {
  kind: "move-down";
  i: number;
}
interface VectorEventAppend<T> {
  kind: "append-item";
  item: T;
}
interface VectorEventDismissModal {
  kind: "dismiss-modal";
}

type VectorEvent<T> =
  | VectorEventShowModal<T>
  | VectorEventUpdateItem<T>
  | VectorEventAppend<T>
  | VectorEventMoveUp
  | VectorEventMoveDown
  | VectorEventDeleteItem
  | VectorEventDismissModal;

export function genericVectorVEditor<T>(
  declResolver: DeclResolver,
  typeExpr: ATypeExpr<T>,
  columnfn: () => AT.Column<T, string>[],
  customize: Customize
): VEditor<T[], VectorState<T>, VectorEvent<T>> {
  const veditor = () => createVEditor(typeExpr, declResolver, customize);

  const initialState: VectorState<T> = {
    values: [],
    modal: null
  };

  function stateFromValue(values: T[]): VectorState<T> {
    return { values, modal: null };
  }

  function validate(): string[] {
    return [];
  }

  function valueFromState(state: VectorState<T>): T[] {
    return state.values;
  }

  function update(state: VectorState<T>, event: VectorEvent<T>): VectorState<T> {
    const newState = { ...state };
    if (event.kind === "show-modal") {
      newState.modal = event.modal;
    } else if (event.kind === "dismiss-modal") {
      newState.modal = null;
    } else if (event.kind === "update-item") {
      newState.values = state.values.slice();
      newState.values[event.i] = event.item;
      newState.modal = null;
    } else if (event.kind === "append-item") {
      newState.values = state.values.slice();
      newState.values.push(event.item);
      newState.modal = null;
    } else if (event.kind === "delete-item") {
      newState.values = state.values.slice();
      newState.values.splice(event.i, 1);
    } else if (event.kind === "move-up") {
      if (event.i > 0) {
        newState.values = state.values.slice();
        newState.values[event.i - 1] = state.values[event.i];
        newState.values[event.i] = state.values[event.i - 1];
      }
    } else if (event.kind === "move-down") {
      if (event.i < state.values.length - 1) {
        newState.values = state.values.slice();
        newState.values[event.i] = state.values[event.i + 1];
        newState.values[event.i + 1] = state.values[event.i];
      }
    }
    return newState;
  }

  function render(
    state: VectorState<T>,
    canEdit: boolean,
    onUpdate: UpdateFn<VectorEvent<T>>
  ): JSX.Element {
    const columns = columnfn();
    if (canEdit) {
      columns.push({
        id: "",
        header: AT.cellContent(
          <Icon
            name="add"
            onClick={() => onUpdate({ kind: "show-modal", modal: { kind: "add" } })}
          />
        ),
        content: (item: T, i: number) => {
          return AT.cellContent(
            <div>
              <Icon
                name="edit"
                onClick={() =>
                  onUpdate({ kind: "show-modal", modal: { kind: "edit", item, i, canEdit: true } })
                }
              />
              <Icon
                name="arrow up"
                disabled={i === 0}
                onClick={() => onUpdate({ kind: "move-up", i })}
              />
              <Icon
                name="arrow down"
                disabled={i >= state.values.length - 1}
                onClick={() => onUpdate({ kind: "move-down", i })}
              />
              <Icon name="trash" onClick={() => onUpdate({ kind: "delete-item", i })} />
            </div>
          );
        }
      });
    } else {
      columns.push({
        id: "",
        header: null,
        content: (item: T, i: number) => {
          return AT.cellContent(
            <div>
              <Icon
                name="edit"
                onClick={() =>
                  onUpdate({ kind: "show-modal", modal: { kind: "edit", item, i, canEdit: false } })
                }
              />
            </div>
          );
        }
      });
    }

    return (
      <div>
        {state.modal ? renderModal(state.modal, onUpdate) : null}
        <AdlTableU columns={columns} values={state.values} />
      </div>
    );
  }

  function renderModal(modal: VectorStateModal<T>, onUpdate: UpdateFn<VectorEvent<T>>) {
    const onDismiss = () => onUpdate({ kind: "dismiss-modal" });
    let header = "";
    let value: T | null = null;
    let onApply = (_item: T) => {return};
    let canEdit = true;

    if (modal.kind === "add") {
      header = "New ...";
      onApply = (item:T) => {onUpdate({ kind: "append-item", item }) };
    } else if (modal.kind === "edit") {
      header = "Edit " + modal.i + " ...";
      value = modal.item;
      onApply = (item:T) => {onUpdate({ kind: "update-item", i: modal.i, item })};
      canEdit = modal.canEdit;
    }

    const vectorModalProps: VectorModalProps<T> = {
      header,
      onDismiss,
      onApply,
      veditor: veditor(),
      value,
      disabled: !canEdit
    };

    return React.createElement(VectorModal, vectorModalProps);
  }

  return {
    initialState,
    stateFromValue,
    validate,
    valueFromState,
    update,
    render
  };
}

function structVectorVEditor<T>(
  declResolver: DeclResolver,
  typeExpr: ATypeExpr<T>,
  customize: Customize
): VEditor<T[], VectorState<T>, VectorEvent<T>> {
  const adlTableInfo: AT.AdlTableInfo<T> = AT.getAdlTableInfo(
    declResolver,
    typeExpr,
    customize.getCustomField
  );
  const columnfn = () => adlTableInfo.columns.map(c => c.column);
  return genericVectorVEditor(declResolver, typeExpr, columnfn, customize);
}

function unionVectorVEditor<T extends {kind:string}>(
  declResolver: DeclResolver,
  typeExpr: ATypeExpr<T>,
  customize: Customize
): VEditor<T[], VectorState<T>, VectorEvent<T>> {
  // For unions by default we only show the discriminator in the
  // table. Use a customization based upon genericVectorVEditor if you
  // want to show more.
  const columnfn = () => [
    {
      id: "disc",
      header: { value: "Type", style: null },
      content: (item: T) => ({ value: item.kind, style: null })
    }
  ];
  return genericVectorVEditor(declResolver, typeExpr, columnfn, customize);
}

interface VectorModalProps<T> {
  header: string;
  onDismiss(): void;
  onApply(unknown: unknown): void;
  veditor: VEditor<T, unknown, unknown>;
  value: unknown;
  disabled: boolean;
}

// We need a wrapper around the semamtic-react modal type, due to issues
// with scroll handling with nested modals. See the issue and workaround
// here:
//      https://github.com/Semantic-Org/Semantic-UI-React/issues/1157
//
//TODO(berto): semantic-ui has been downgraded to make this hack still work (Look into why the new event-stack (on semantic-ui 0.88.2) does not work with this workaround)
class VectorModal<T> extends React.Component<VectorModalProps<T>> {
  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName("ui page modals").length;
    if (anotherModal > 0) {
      document.body.classList.add("scrolling", "dimmable", "dimmed");
    }
  }

  render() {
    return (
      <Modal open={true} onUnmount={this.fixBody} onClose={this.props.onDismiss}>
        <Header>{this.props.header}</Header>
        <Modal.Content style={{ margin: 0 }}>
          <AdlEditor
            veditor={this.props.veditor}
            value={this.props.value}
            disabled={this.props.disabled}
            onApply={this.props.onApply}
            onCancel={this.props.onDismiss}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

function vectorVEditor<T>(
  declResolver: DeclResolver,
  typeExpr: ATypeExpr<T>,
  customize: Customize
): VEditor<T[], VectorState<tabular.SingleField<T>>, VectorEvent<T>> {
  type S = VectorState<tabular.SingleField<T>>;
  type E = VectorEvent<T>;

  // For now we reuse the struct editor by embedding the (non-struct) value in a
  // single element struct
  const structTypeExpr = tabular.texprSingleField(typeExpr);
  const veditor0 = structVectorVEditor(declResolver, structTypeExpr, customize);

  function stateFromValue(value: T[]): S {
    return veditor0.stateFromValue(value.map(v => ({ value: v })));
  }

  function valueFromState(state: S): T[] {
    return veditor0.valueFromState(state).map(v => v.value);
  }

  return {
    initialState: veditor0.initialState,
    stateFromValue,
    validate: veditor0.validate,
    valueFromState,
    update: veditor0.update as (state: S, event: E) => S,
    render: veditor0.render as (state: S, canEdit: boolean, onUpdate: UpdateFn<E>) => JSX.Element,
  };
}

/// Map a value editor from type A to a corresponding value
/// editor over type B.
export function mappedVEditor<A,B,S,E>(
  veditor: VEditor<A,S,E>,
  aFromB: (b:B) => A,
  bFromA: (a:A) => B
  ) : VEditor<B,S,E> {
  return {
    initialState: veditor.initialState,
    stateFromValue: (b:B) => veditor.stateFromValue(aFromB(b)),
    validate: veditor.validate,
    valueFromState: (s:S) => bFromA(veditor.valueFromState(s)),
    update: veditor.update,
    render: veditor.render,
  };
}


function isEnum(fields: adltree.Field[]): boolean {
  for (const f of fields) {
    const isVoid =
      f.astField.typeExpr.typeRef.kind === "primitive" &&
      f.astField.typeExpr.typeRef.value === "Void";
    if (!isVoid) {
      return false;
    }
  }
  return true;
}

function isMaybe(typeExpr: adlast.TypeExpr): boolean {
  if (typeExpr.typeRef.kind === "reference") {
    return (
      typeExpr.typeRef.value.moduleName === "sys.types" && typeExpr.typeRef.value.name === "Maybe"
    );
  }
  return false;
}

function listFromNull<T>(v: T | null): T[] {
  if (v === null) {
    return [];
  } else {
    return [v];
  }
}

// Convert snake/camel case to human readable spaced name
export function fieldLabel(name: string): string {
  return (
    name
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function(str) {
        return str.toUpperCase();
      })
      // replace _ with space
      .replace(/_/g, " ")
  );
}
