import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { AppService } from '../../../adl-gen/app-service';
import { OrgListEntry } from '../../../adl-gen/whistle/xsync/api';
import { useLoggedInUserContext } from '../../../hooks/loggedInContext';
import XeroDisconnectConfirmDialog from './disconnect-confirm';

// Importing the SVG Xero Buttons as PNG - Somehow the contents of the SVG were breaking webpack (segfaulting)
// (PNG rendered at 2x the resolution)

//@ts-ignore
import connectBlue from './connect-blue.png';

//@ts-ignore
import disconnectBlue from './disconnect-blue.png';


const styles = require("./xeroButtons.css");

export function XeroConnectBlue(props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  return <img className={styles.connectBlue} src={connectBlue} {...props}/>;
}

export function XeroDisconnectBlue(props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  return <img className={styles.disconnectBlue} src={disconnectBlue} {...props}/>;
}

export function XeroDisconnectButton(props: {org: OrgListEntry, reloadOrgs: ()=>Promise<void>}) {


  const [loading, setLoading] = React.useState<boolean>(false);
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;
  
  return <>
    <XeroDisconnectConfirmDialog
      org={props.org}
      onDisconnect={
        async () => {
          setLoading(true);
          await service.xeroDisconnect(props.org.xeroOrg.id);
          await props.reloadOrgs();
          setLoading(false);
        }
      }/>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>;
}


export function XeroConnectButton() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;

  // Xero Connect button + a backdrop to fade the whole app to a loader while navigating away to Xero.com for login. 
  return <>
    <XeroConnectBlue
      onClick={async () => {
        setLoading(true);
        const xeroAuthRedirectUrl = await service.xeroAuthorization();
        window.location.replace(xeroAuthRedirectUrl);
        // app navigates away and never gets here
      }}
    />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>;
}
