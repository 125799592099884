import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { service } from "../../../service/makeApiServices";

//@ts-ignore
import xeroLogo from './xeroLogo.svg';

export function XeroIcon() {
  return <img src={xeroLogo} alt="Xero" width="34" />;
}

export function XeroLoginButton() {
  const [loading, setLoading] = React.useState<boolean>(false);

  // Xero Connect button + a backdrop to fade the whole app to a loader while navigating away to Xero.com for login. 
  return <>
  <Grid container spacing={2}>
     <Grid item xs={6}>
      <Button type="submit" variant="outlined" size="medium" startIcon={<XeroIcon />} onClick={async () => {
        setLoading(true);
        const xeroLoginAuthRedirectUrl = await service.app.xeroLoginAuthorization();
        window.location.replace(xeroLoginAuthRedirectUrl);
        // app navigates away and never gets here
      }}>
              Sign in with Xero
      </Button>
    </Grid>
  </Grid>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>;
  
}
