import { BrowserHttp } from "@hx/hx/service/browser-http";
import { HttpWithRetries } from "@hx/hx/service/retrying-http";
import { HttpServiceError } from "@adltools/service/http-service-error";
import { HttpService as AdminHttpService } from "@adltools/adminui/http-service";
import { RESOLVER } from "../adl-gen/resolver";
import { AppService } from "../adl-gen/app-service";
import { clearLocalToken } from "../app/identity-state";
import { ApiServices } from "./api-services";

// tslint:enable:no-console
// Global error handler, includes errors that occur in the handler
window.addEventListener("error", (event: ErrorEvent) => handleError("global error", event.error, event)
);
// Global unhandled rejection handler
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => handleError("unhandled rejection", event.reason, event)
);
/**
 * Last resort error handler that alerts the user.
 */
function handleError(
  eventType: "global error" | "unhandled rejection",
  error: { publicMessage?: string; },
  event: Event
) {
  // tslint:disable-next-line:no-console
  console.error(
    `${eventType}: ${String(error)}, error:`,
    error,
    "event:",
    event
  );
  if (error.publicMessage) {
    window.alert(error.publicMessage);
  } else {
    window.alert(
      "Sorry, an unhandled error has occurred. Please try refreshing and performing the action again."
    );
  }
}
// Configure http to retry up to 8 times, with an initial delay of 100ms, with
// exponential increaes.  The precise delays are randomised, but this will
// result in a typical max delay before failure of 25 seconds
const http = new HttpWithRetries(new BrowserHttp(), 8, 100);
function handleHttpServiceError(error: HttpServiceError) {
  // Check if it's an authorization error
  if (error.status === 401) {
    const publicMessageFragment = error.publicMessage
      ? `: ${error.publicMessage}`
      : "";
    window.alert(
      `Sorry, an authorization error occurred ${publicMessageFragment}. You will be logged out.`
    );
    clearLocalToken();
    return;
  }
}
function makeApiServices(authToken?: string): ApiServices {
  const app = new AppService(
    http,
    "/_a",
    RESOLVER,
    authToken,
    handleHttpServiceError
  );

  const admin = new AdminHttpService(
    http,
    "/_a",
    RESOLVER,
    authToken,
    handleHttpServiceError
  );

  return {
    app,
    admin
  };
}

//Create an unauthenticated servier for logins, and other public endpoints.
export const service = makeApiServices();

export function createApiServices(authToken?: string) {
  if (authToken === undefined) {
    return service;
  } else {
    return makeApiServices(authToken);
  }
}
