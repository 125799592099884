import React, { useState } from "react";
import * as SR from "semantic-ui-react";
import styled from 'styled-components';

import { AdlEditor } from "../../adl-editor";
import { Table } from "../../adl-gen/common/adminui/api";
import { InternalLink } from "../../components/InternalLink";
import { ActionData, AdminHrefFactory, Metadata } from "../utils";


export interface AdminUiMainPageProps {
  metadata: Metadata;
  hrefFactory: AdminHrefFactory,
  onClickTable(table: string): void;
  actions: ActionData[];
}

type ModalState =
  | { kind: "show-form"; action: ActionData }
  | { kind: "show-progress"; action: ActionData }
  | { kind: "show-resp"; action: ActionData; resp: unknown };

export const AdminUiMainPage = (props:AdminUiMainPageProps) => {

  const [modalState,setModalState] = useState<ModalState | null>(null);

  const tables: Table[] = [];
  props.metadata.tableNames.forEach(tn => {
    const table = props.metadata.tableMap[tn];
    if (table !== undefined) {
      tables.push(table);
    }
  });


  function renderModal(state: ModalState): JSX.Element {
    switch (state.kind) {
      case "show-form": {
        const content = (
          <AdlEditor
            key="req"
            value={null}
            veditor={state.action.veditorReq}
            onCancel={onCancelModal}
            onApply={(req: unknown) => runRequest(state.action, req)}
            allowRaw={state.action.jsonBindingReq}
          />
        );
        return (
          <SR.Modal open={true} onClose={onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path}
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
      case "show-progress": {
        const content = <SR.Icon loading name="spinner" size="big" />;
        return (
          <SR.Modal open={true} onClose={onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path} results:
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
      case "show-resp": {
        const content = (
          <AdlEditor
            key="resp"
            value={state.resp}
            veditor={state.action.veditorResp}
            disabled={true}
            onClose={onCancelModal}
            allowRaw={state.action.jsonBindingResp}
          />
        );
        return (
          <SR.Modal open={true} onClose={onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path} results:
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
    }
  };

  function renderTables(tables1: Table[]): JSX.Element {
    return (
      <div>
        <SR.Table celled>
          <SR.Table.Header>
            <SR.Table.Row>
              <SR.Table.HeaderCell>Data tables</SR.Table.HeaderCell>
              <SR.Table.HeaderCell />
            </SR.Table.Row>
          </SR.Table.Header>
          <SR.Table.Body>{tables1.map(renderTable)}</SR.Table.Body>
        </SR.Table>
        <SR.Table celled>
          <SR.Table.Header>
            <SR.Table.Row>
              <SR.Table.HeaderCell>Actions</SR.Table.HeaderCell>
              <SR.Table.HeaderCell />
            </SR.Table.Row>
          </SR.Table.Header>
          <SR.Table.Body>{props.actions.map(renderAction)}</SR.Table.Body>
        </SR.Table>
      </div>
    );
  };

  function renderTable(table: Table): JSX.Element {
    return (
      <SR.Table.Row key={table.name}>
        <SR.Table.Cell>
          <InternalLink
            text= {table.name}
            onClick={() => props.onClickTable(table.name)}
            href={props.hrefFactory.fromAdminRoute({route:'table', table:table.name})}
          />
        </SR.Table.Cell>
        <SR.Table.Cell>{table.description}</SR.Table.Cell>
      </SR.Table.Row>
    );
  };

  function renderAction(actionData: ActionData): JSX.Element | null {
    return (
      <SR.Table.Row key={actionData.path}>
        <SR.Table.Cell>
          <SR.Button onClick={() => onClickAction(actionData)}>
            {actionData.method} {actionData.path}
          </SR.Button>
        </SR.Table.Cell>
        <SR.Table.Cell>{actionData.description}</SR.Table.Cell>
      </SR.Table.Row>
    );
  };

  const onCancelModal = () => {
    setModalState(null);
  };

  const onClickAction = (actionData: ActionData) => {
    setModalState({ kind: "show-form", action: actionData });
  };

  const runRequest = async (actionData: ActionData, req: unknown) => {
    setModalState({ kind: "show-progress", action: actionData });
    const resp = await actionData.call(req);
    setModalState({ kind: "show-resp", action: actionData, resp });
  };

  return (
    <PageSty>
      {renderTables(tables)}
      {modalState === null ? null : renderModal(modalState)}
    </PageSty>
  );

}

const PageSty = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  font-size: 14px;
`

