import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { service } from "../../service/makeApiServices";
import { assertNever } from "../../tslibs/hx/src/util/types";
import { PublicLayout } from "../layout/PublicLayout";
import { PublicSetupLayout } from "../layout/PublicSetupLayout";

export function ForgotPasswordPage() {
  return <PublicSetupLayout>
    <Container maxWidth={"xs"}>
      <Stack spacing={1}>
        <ForgotPassword/>
      </Stack>
    </Container>
  </PublicSetupLayout>;
}

export function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  
  type State = "initial"|"success"|"error";
  const [state, setState] = useState<State>("initial");

  switch(state) {
    case 'initial':
    {
      const isValid = email;

      return <Stack spacing={1}>
        <Typography>
          Reset password
        </Typography>
        
        <TextField
          id="forgot-pw-email-input"
          label="Email"
          type="email"
          value={email}
          onChange={(ev)=>setEmail(ev.target.value)}
        />
        <Button disabled={!isValid} variant="outlined" color="primary"
          onClick={async ()=>{
            const resp = await service.app.forgotPassword({
              email
            });

            if(resp === 'success') {
              setState('success');
            } else {
              setState('error');
            }
          }}
        >
          Submit
        </Button>
      </Stack>;  
    }  
    
    case 'success': {
      return <Stack spacing={1}>
        <Typography>
          Success - Please check your email to reset password.
        </Typography>
      </Stack>;
    }
    
    case 'error':
      return <div>error</div>

    default:
      assertNever(state)
  }
}