import { HttpFetch } from "@hx/hx/service/http";

import { Paginated, Unit } from "../adl-gen/common";
import * as AR from "../adl-gen/common/adminui/api";
import { MetaAdlDecl } from "../adl-gen/common/adminui/db";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import { TableQuery } from "../adl-gen/common/tabular";
import { DeclResolver } from "../adl-gen/runtime/adl";
import { HttpServiceBase } from "../service/http-service-base";
import { HttpServiceError } from "../service/http-service-error";
import { PostFn } from "../service/types";

import { AdminService } from "./service";

/**
 * The adminUI HttpService
 */
export class HttpService extends HttpServiceBase implements AdminService {
  postQueryTables: PostFn<TableQuery, Paginated<AR.Table>>;
  postQueryDecls: PostFn<TableQuery, Paginated<MetaAdlDecl>>;
  postCreate: PostFn<AR.CreateReq, AR.DbResult<DbKey<AR.DbRow>>>;
  postQuery: PostFn<AR.QueryReq, Paginated<WithDbId<AR.DbRow>>>;
  postQueryWithoutIds: PostFn<AR.QueryReq, Paginated<AR.DbRow>>;
  postUpdate: PostFn<AR.UpdateReq, AR.DbResult<Unit>>;
  postDelete: PostFn<AR.DeleteReq, AR.DbResult<Unit>>;
  postDbKeyLabels: PostFn<AR.DbKeyLabelReq[], AR.DbKeyLabelResp[]>;

  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** Resolver for ADL types */
    resolver: DeclResolver,
    /** The authentication token (if any) */
    authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void
  ) {
    super(http, baseUrl, resolver, authToken, handleError);

    const adminApi = this.annotatedApi(
      AR.snAdminApiRequests,
      AR.makeAdminApiRequests({})
    );
    this.postQueryTables = this.mkPostFn(adminApi.queryTables);
    this.postQueryDecls = this.mkPostFn(adminApi.queryDecls);
    this.postCreate = this.mkPostFn(adminApi.create);
    this.postQuery = this.mkPostFn(adminApi.query);
    this.postQueryWithoutIds = this.mkPostFn(adminApi.queryWithoutIds);
    this.postUpdate = this.mkPostFn(adminApi.update);
    this.postDelete = this.mkPostFn(adminApi.delete);
    this.postDbKeyLabels = this.mkPostFn(adminApi.dbKeyLabels);
  }

  async queryTables(req: TableQuery): Promise<Paginated<AR.Table>> {
    return this.postQueryTables.call(req);
  }

  async queryDecls(req: TableQuery): Promise<Paginated<MetaAdlDecl>> {
    return this.postQueryDecls.call(req);
  }

  async create(req: AR.CreateReq): Promise<AR.DbResult<DbKey<AR.DbRow>>> {
    return this.postCreate.call(req);
  }

  async query(req: AR.QueryReq): Promise<Paginated<WithDbId<AR.DbRow>>> {
    return this.postQuery.call(req);
  }

  async queryWithoutIds(req: AR.QueryReq): Promise<Paginated<AR.DbRow>> {
    return this.postQueryWithoutIds.call(req);
  }

  async update(req: AR.UpdateReq): Promise<AR.DbResult<Unit>> {
    return this.postUpdate.call(req);
  }

  async delete(req: AR.DeleteReq): Promise<AR.DbResult<Unit>> {
    return this.postDelete.call(req);
  }

  async dbKeyLabels(
    req: AR.DbKeyLabelReq[]
  ): Promise<AR.DbKeyLabelResp[]> {
    return this.postDbKeyLabels.call(req);
  }
}
