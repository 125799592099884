import { assertNotUndefined } from "@hx/util/types";
import * as React from "react";

import { LoggedInContext } from "../app/app";
import { IdentityState, LoginState } from "../app/identity-state";

/**
 * Retrieves the logged in context.user or throws an exception
 */
export const useLoggedInUserContext = (): IdentityState => {
  const x = useLoggedInContext();
  return assertNotUndefined(x.user);
};

/**
 * Retrieves the logged in context or throws an exception
 */
export const useLoggedInContext = (): LoginState => {
  return assertNotUndefined(React.useContext(LoggedInContext).loginState);
};
