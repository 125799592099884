/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_adminui_api } from "./common/adminui/api";
import { _AST_MAP as common_adminui_db } from "./common/adminui/db";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_types } from "./sys/types";
import { _AST_MAP as whistle_xsync_api } from "./whistle/xsync/api";
import { _AST_MAP as whistle_xsync_db } from "./whistle/xsync/db";
import { _AST_MAP as whistle_xsync_uiconfig } from "./whistle/xsync/uiconfig";

export const ADL: { [key: string]: ScopedDecl } = {
  ...common,
  ...common_adminui_api,
  ...common_adminui_db,
  ...common_config_aws,
  ...common_config_log,
  ...common_db,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_types,
  ...whistle_xsync_api,
  ...whistle_xsync_db,
  ...whistle_xsync_uiconfig,
};

export const RESOLVER = declResolver(ADL);
