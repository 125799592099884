// fetch, display and select plan pricing

import React, { useEffect, useState } from "react";
import { StripePrice, StripeProduct } from "../../../adl-gen/whistle/xsync/api";
import { useLoggedInUserContext } from "../../../hooks/loggedInContext";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';

import {constantCase} from 'change-case';
import { assertNever } from "../../../tslibs/hx/src/util/types";
import { TireRepairRounded } from "@mui/icons-material";

export interface ShowPlanPricesProps {
  onSelectPlan: (plan: StripePrice)=>void
};

export function ShowPlanPrices(props: ShowPlanPricesProps) {
  const [loading, setLoading] = useState<number>(0);
  const [products, setProducts] = useState<StripeProduct[]>([]);

  const [selected, setSelected] = useState<StripeProduct|null>(null);

  const { app: service } = useLoggedInUserContext().apis;

  const loadData = async () => {
    setLoading(l=>l+1);
    const resp = await service.listProducts({});
    setProducts(resp.products);
    setLoading(l=>l-1);

    // convenience auto select if only one option exists
    if(resp.products.length === 1) {
      setSelected(resp.products[0]);
    }
  };

  useEffect(() => {
    void loadData();
  }, []);

  const selectedCardSx = {
    //border: 1,
    borderColor: 'primary.main',
    boxShadow: 3,
  };
  const nonSelectedCardSx = {
  };

  return <>
    {(loading > 0) && <CircularProgress />}
    {(loading === 0) && <>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          {products.map(prod=>{
            const cardSelected = selected && (prod.id === selected.id);
            const cardSx = cardSelected ? selectedCardSx : nonSelectedCardSx;
            return <Grid item xs={4} key={prod.id}>
              <Card variant="outlined" sx={cardSx}>
                <CardActionArea onClick={()=>{
                  setSelected(prod);
                }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                      {prod.name}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {prod.description}
                    </Typography>

                    <ShowPrice {...prod.price}/>
                  </CardContent>
                </CardActionArea>
              </Card>  
            </Grid>;
          }
          )}
        </Grid>

        {selected!==null && 
          <Box>
            <Button variant="contained" size="small" onClick={()=>{
            props.onSelectPlan(selected.price);
          }}>Checkout</Button>
          </Box>
          
        }
      </Stack>
    </>}
  </>;
}

function ShowPrice(p: StripePrice) {

  const currency = constantCase(p.currency);

  const recurringInterval = p.recurring?.interval;
  const recurringIntervalCount = p.recurring?.interval_count;

  const intervalCountMsg = recurringIntervalCount===1 ? "" : `${recurringIntervalCount}`;

  const recurrenceMsg = `per ${intervalCountMsg} ${recurringInterval}`;
  const currencyMsg = currency;

  let priceMsg = "";
  switch(p.billingScheme.kind) {
    case 'perUnit': {
      const perUnit = p.billingScheme.value;
      priceMsg = `${perUnit.unit_amount/100}`;
    }
    break;

    case 'tiered': {
      // unimplemented cop-out:
      priceMsg = "Tiered pricing";
    }
    break;

    default:
      assertNever(p.billingScheme);
  }

  return <>
    <div>{currencyMsg} {priceMsg} {recurrenceMsg}</div>
  </>
}