// layout for logged in User pages

import React from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/AppRoutes";
import { IdentityState, LoginState } from "../../app/identity-state";
import {
  useLoggedInContext,
  useLoggedInUserContext
} from "../../hooks/loggedInContext";
import { Layout } from "./Layout";

export interface UserLayoutProps {
  children?: React.ReactNode;
}

export function UserLayout(props: UserLayoutProps) {
  const identity: IdentityState = useLoggedInUserContext();
  const loginState: LoginState = useLoggedInContext();

  const { profile } = identity;
  const history = useHistory();

  const maybeAdmin = profile.isAdmin
    ? [
        {
          text: "Admin",
          onClick: () => {
            history.push(AppRoutes.Admin);
          }
        }
      ]
    : [];

  return (
    <Layout
      subTitle={profile.fullname}

      buttonItems={[
        {
          text: "Subscription",
          onClick: () => {
            history.push(AppRoutes.Subscription);
          }
        },
        {
          text: "Organisations",
          onClick: () => {
            history.push(AppRoutes.Organisations);
          }
        },
        {
          text: "Export",
          onClick: () => {
            history.push(AppRoutes.ExportData);
          }
        }
      ]}

      menuItems={[
        {
          text: "Main",
          onClick: () => {
            history.push(AppRoutes.Index);
          }
        },
        ...maybeAdmin,
        {
          text: "Logout",
          onClick: () => {
            loginState.onLogout();
          }
        }
      ]}
    >
      {props.children}
    </Layout>
  );
}
