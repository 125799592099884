import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { PublicSetupLayout } from "../layout/PublicSetupLayout";
import TextField from "@mui/material/TextField";
import { service } from "../../service/makeApiServices";
import { AppRoutes } from "../../app/AppRoutes";
import { Typography } from "@mui/material";

export function WelcomePage() {
  return <PublicSetupLayout>
    <Container maxWidth={"xs"}>
      <Stack spacing={1}>
        <WelcomeSetPassword/>
      </Stack>
    </Container>
  </PublicSetupLayout>;
}

function WelcomeSetPassword() {
  const history = useHistory();
  const params = useParams();
  const token = params['token'] as string;

  const [password, setPassword] = useState<string>("");
  const [pwConfirm, setPwConfigm] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const isValid = password && pwConfirm && password === pwConfirm;

  if(error) {
    return <Stack spacing={1}>
      <Typography>
        There has been an error
      </Typography>
    </Stack>
  }

  return <Stack spacing={1}>
    <Typography>
      Welcome, please set a password to continue
    </Typography>

    <TextField
      id="welcome-password-input"
      label="Password"
      type="password"
      autoComplete="current-password"
      value={password}
      onChange={(ev)=>setPassword(ev.target.value)}
    />
    <TextField
      id="welcome-password-confirm"
      label="Confirm Password"
      type="password"
      value={pwConfirm}
      onChange={(ev)=>setPwConfigm(ev.target.value)}
    />
    <Button disabled={!isValid} variant="contained" color="primary"
      onClick={async ()=>{
        const resp = await service.app.activate({
          welcomeToken: token,
          password
        });

        if(resp === 'success') {
          history.push(AppRoutes.Login);
        } else {
          setError(true);
        }
      }}
    >
      Confirm
    </Button>
  </Stack>
}
