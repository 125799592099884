// layout for Public (non logged in) pages

import React from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/AppRoutes";
import { Layout } from "./Layout";

export interface PublicSetupLayoutProps {
  children?: React.ReactNode;
}

/** A layout for register / welcome / reset password pages */
export function PublicSetupLayout(props: PublicSetupLayoutProps) {
  return (
    <Layout
      menuItems={[]}
    >
      {props.children}
    </Layout>
  );
}
